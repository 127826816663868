import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogOut, reset, getMe } from "../../redux/authSlice";
import * as Icon from 'react-feather';
import UserDropdown from "../molecules/Navbar/UserDropdown";

const Navigation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [isTop, setIsTop] = useState(true);
    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 0) {
            setIsTop(false);
        } else {
            setIsTop(true);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const logout = () => {
        if (user) {
            dispatch(LogOut());
            dispatch(reset());
            navigate("/login");
        }
    }
    return (
        <nav className="navbar px-0 fixed-top navbar-expand-md navbar-light bg-light">
            {/* <nav className={`navbar px-0 fixed-top navbar-expand-md navbar-${isTop ? '' : 'onesimstore'} bg-light`}> */}
            {/* <nav className="navbar navbar-expand-lg navbar-light bg-light"> */}
            <div className="container">
                <Link className="navbar-brand" to={user ? "/dashboard" : "/"}>
                    <img className="" src={'/assets/images/Researcher Hub 1.png'} alt="Logo" style={{ height: 50 }} />
                    <ul className="navbar-nav w-100 mx-auto d-block ">
                        <li className="nav-item w-100">
                        </li>
                    </ul>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav w-50 mx-auto d-none d-md-block">
                        <li className="nav-item w-100">

                        </li>
                    </ul>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
                        {user ?
                            <>
                                {/* <li className="nav-item mx-md-3">
                                    <Link className="nav-link" to="/cart">
                                        <div className="position-relative">
                                            <Icon.ShoppingCart size={18} />
                                            <span className="d-sm-none ms-3">Cart </span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item mx-md-3">
                                    <Link className="nav-link" to="/wishlist">
                                        <Icon.Heart size={18} />
                                        <span className="d-sm-none ms-3">Wishlist</span>
                                    </Link>
                                </li> */}
                                <li className="nav-item mx-md-3 dropdown hoverable dropdown-hover">
                                    {/* for mobile click not on hover */}
                                    <Link className="d-sm-none nav-link  dropdown-toggle" to="#" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        <Icon.User size={18} />
                                        <span className=" ms-3">Account</span>
                                    </Link>
                                    {/* for pc, hover only */}
                                    <Link className="d-none d-sm-block nav-link " to="#" id="navbarDropdown" aria-expanded="false">
                                        <Icon.User size={18} />
                                    </Link>
                                    <UserDropdown logout={logout}></UserDropdown>
                                </li>
                            </>
                            :
                            <li className="nav-item mx-md-0">
                                <Link to="/login" className="nav-link">Login</Link>
                            </li>
                        }
                    </ul>


                </div>
            </div>
        </nav >
    );
};

export default Navigation;