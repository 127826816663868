import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
const EditResearch = () => {
  const [ProductID, setProductID] = useState("");
  const [ResearchStartDate, setResearchStartDate] = useState("");
  const [ResearchEndDate, setResearchEndDate] = useState("");
  const [ResearchName, setResearchName] = useState("");
  const [ResearchDescription, setResearchDescription] = useState("");
  const [ResearchTags, setResearchTags] = useState("");
  const [ResearchMethods, setResearchMethods] = useState([]);
  const [ResearchOutputs, setResearchOutputs] = useState([]);
  const [ResearchLinks, setResearchLinks] = useState([]);
  const [ResearchResearchers, setResearchResearchers] = useState([]);

  //Populate select
  const [products, setProducts] = useState([]);
  const [methods, setMethods] = useState([]);
  const [outputs, setOutputs] = useState([]);
  const [links, setLinks] = useState([]);
  const [researchers, setResearchers] = useState([]);

  //For input purposes
  const [method, setMethod] = useState("");
  const [output, setOutput] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    getResearchById();
    getProducts();
    getMethods();
    getOutputs();
    getLinks();
    getResearchers();
  }, []);
  const handleChange = (e, i) => {
    var index = e.nativeEvent.target.selectedIndex;

    // 1. Make a shallow copy of the items
    let curStates = [...ResearchLinks];
    // 2. Make a shallow copy of the item you want to mutate
    let curState = { ...curStates[i] };
    // 1. Make a shallow copy of the items
    let resStates = [...ResearchResearchers];
    // 2. Make a shallow copy of the item you want to mutate
    let resState = { ...resStates[i] };

    switch (e.target.name) {
      case "method":
        if (!ResearchMethods.some(v => (v.ResearchMethodName === e.target.value))) {
          setResearchMethods(oldArray => [...oldArray, {
            ResearchMethodName: e.target.value,
            ResearchMethodName: e.nativeEvent.target[index].text
          }]);
        }
        setMethod("");
        break;
      case "output":
        if (!ResearchOutputs.some(v => (v.ResearchOutputID === e.target.value))) {
          setResearchOutputs(oldArray => [...oldArray, {
            ResearchOutputID: e.target.value,
            ResearchOutputName: e.nativeEvent.target[index].text
          }]);
        }
        setOutput("");
        break;

      case "link":
        curState.LinkID = parseInt(e.target.value);
        curState.LinkName = e.nativeEvent.target[index].text;
        curState.LinkID = parseInt(e.target.value);
        curStates[i] = curState;
        setResearchLinks(curStates);
        break;
      case "linkurl":
        curState.LinkURL = e.target.value;
        curStates[i] = curState;
        setResearchLinks(curStates);
        break;
      case "researcher":
        resState.ResearcherID = parseInt(e.target.value);
        resState.ResearcherName = e.nativeEvent.target[index].text;
        resState.ResearcherID = parseInt(e.target.value);
        resStates[i] = resState;
        setResearchResearchers(resStates);
        break;
    }
  }
  function removeVar(type, data) {
    switch (type) {
      case "method":
        setResearchMethods((state) => state.filter((item) => item !== data));
        break;
      case "output":
        setResearchOutputs((state) => state.filter((item) => item !== data));
        break;
      case "link":
        setResearchLinks((state) => state.filter((item) => item !== data));
        break;
      case "researcher":
        setResearchResearchers((state) => state.filter((item) => item !== data));
        break;
    }
  }
  function addVar(type) {
    switch (type) {
      case "link":
        setResearchLinks(oldArray => [...oldArray, {
          LinkID: 1,
          LinkURL: "",
          link: {
            LinkID: 1,
          }
        }]);
        break;
      case "researcher":
        setResearchResearchers(oldArray => [...oldArray, {
          ResearcherID: 1,
          researcherdetails: {
            ResearcherID: 1
          }
        }])
        break;
    }
  }
  const updateResearch = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/researches/${id}`,
        {
          Research: {
            ProductID,
            ResearchStartDate,
            ResearchEndDate,
            ResearchName,
            ResearchDescription,
            ResearchTags
          },
          ResearchOutputs,
          ResearchMethods,
          ResearchLinks,
          ResearchResearchers
        });
      navigate(`/research/${id}`);
    } catch (error) {
      console.log(error);
    }
  };
  const getLinks = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/links`);
    setLinks(response.data);
  };
  const getResearchers = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/researchers`);
    setResearchers(response.data);
  };
  const getProducts = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
    setProducts(response.data);
  };
  const getMethods = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/methods`);
    setMethods(response.data);
  };
  const getOutputs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/outputs`);
    setOutputs(response.data);
  };
  const getResearchById = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/researches/${id}`);
    setProductID(response.data.ProductID);
    setResearchStartDate(response.data.ResearchStartDate);
    setResearchEndDate(response.data.ResearchEndDate);
    setResearchName(response.data.ResearchName);
    setResearchDescription(response.data.ResearchDescription);
    setResearchTags(response.data.ResearchTags);
    setResearchMethods(response.data.researchmethods);
    setResearchOutputs(response.data.researchoutputs);
    setResearchLinks(response.data.researchlinkdetails);
    setResearchResearchers(response.data.researchers)
  };
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  return (
    <div className="container">
      <form onSubmit={updateResearch}>
        <div className="row py-3">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h2>Edit Research</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mr-auto">
                      <input type="text" className="form-control" value={ResearchName} onChange={(e) => setResearchName(e.target.value)} label="Research Name" ></input>
                    </div>
                    <div className="form-group mr-auto">
                      <label htmlFor="exampleFormControlSelect1">Product</label>
                      <select findby="product" name="product" className="form-control" id="product" value={ProductID} onChange={(e) => setProductID(e.target.value)} required>
                        {products.map((product, index) => (
                          <option value={product.ProductID}>{product.ProductName}</option>
                        ))}
                      </select>
                    </div>





                  </div>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <div className="col-md-6">
                        <label className="required">Method</label>
                        <select findby="method" value={method} name="method" className="form-control" id="method"
                          onChange={handleChange}>
                          <option value="" disabled>Add method</option>
                          {methods.map((method, index) => (
                            <option value={method.ResearchMethodName}>{method.ResearchMethodName}</option>
                          ))}
                        </select>
                        <div id="selectedmethod" className="mt-1">
                          {ResearchMethods?.map((method, index) => (
                            <span className="badge bg-secondary mr-1">{method.ResearchMethodName} <a onClick={() => removeVar('method', method)} href="#" className="fa-regular fa-circle-xmark"></a></span>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="required">Output</label>
                        <select findby="output" value={output} name="output" className="form-control" id="output"
                          onChange={handleChange} >
                          <option value="" disabled>Add output</option>
                          {outputs.map((output, index) => (
                            <option value={output.ResearchOutputID}>{output.ResearchOutputName}</option>
                          ))}
                        </select>
                        <div id="selectedoutput" className="mt-1">
                          {ResearchOutputs?.map((output, index) => (
                            <span className="badge bg-secondary mr-1">{output.ResearchOutputName}  <a onClick={() => removeVar('output', output)} href="#" className="fa-regular fa-circle-xmark"></a></span>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mr-auto ">
                          <label className="label">Conduct Start Date</label>
                          <input className="form-control" value={ResearchStartDate} onChange={(e) => setResearchStartDate(e.target.value)} type="date" required></input>
                        </div></div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label">Conduct End Date</label>
                          <input className="form-control" value={ResearchEndDate} onChange={(e) => setResearchEndDate(e.target.value)} type="date" required></input>
                        </div>
                      </div>
                    </div>


                    {/* <div className="form-group">
                      <label className="label">Research Description</label>
                      <Editor
                        apiKey='4o4qfyh49s5icbiq5f4e1sj96ymkwoy5jbcln48dp7wcl7un'
                        onInit={(evt, editor) => editorRef.current = editor}
                        value={ResearchDescription}
                        onEditorChange={(newValue, editor) => {
                          setResearchDescription(newValue);
                          // setText(editor.getContent({format: 'text'}));
                        }}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                          ],
                          toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                          // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}/>

                    </div> */}
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="required">Researcher</label>
                      <div className="row">
                        {ResearchResearchers?.map((researcher, index) => (
                          <div className="col-md-6">
                            <div className="input-group mb-3">
                              <select findby="researcher" value={researcher.ResearcherID} name="researcher" className="form-control" id={`researcher-${index}`}
                                onChange={(event) => handleChange(event, index)} >
                                {researchers?.map((res, index) => (
                                  <option value={res.ResearcherID}>{res.ResearcherName}</option>
                                ))}
                              </select>
                              <div className="input-group-append">
                                <span className="input-group-text"><Link to="#" className="fa-regular fa-trash-can" onClick={() => removeVar("researcher", researcher)}></Link></span>
                              </div>
                            </div></div>
                        ))}
                        <div className="col-md-6">
                          <button onClick={() => addVar("researcher")} size="sm" type="button"><i className="fa-solid fa-plus"></i></button>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="required">Links</label>
                      {ResearchLinks?.map((researchlink, index) => (
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <select findby="link" value={researchlink.LinkID} name="link" className="form-control" id={`link-${index}`}
                              onChange={(event) => handleChange(event, index)} >
                              {links?.map((link, index) => (
                                <option value={link.LinkID}>{link.LinkName}</option>
                              ))}
                            </select>
                          </div>
                          <input name="linkurl" onChange={(event) => handleChange(event, index)} id={`linkurl-${index}`} value={researchlink.LinkURL} type="text" className="form-control" />
                          <div className="input-group-append">
                            <span className="input-group-text"><Link to="#" className="fa-regular fa-trash-can" onClick={() => removeVar("link", researchlink)}></Link></span>
                          </div>
                        </div>
                      ))}
                      <button onClick={() => addVar("link")} size="sm" type="button"><i className="fa-solid fa-plus"></i></button>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input label="Tags" type="text" name="tags" value={ResearchTags} onChange={(e) => setResearchTags(e.target.value)} placeholder="Separate by using comma" ></input>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button size="sm" className="d-inline mr-2 w-100" type="submit">Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form >
    </div>
  );
};

export default EditResearch;
