import React from "react";
import { STATUS_OPTIONS, TYPE_OPTIONS, SEVERITY_OPTIONS, JOURNEY_OPTIONS } from "../../../constants/trackerOptions.js";
import { Link } from "react-router-dom";

const TrackerTable = ({
    trackers,
    trackerUpdates,
    editingField,
    handleEditClick,
    handleTrackerChange,
    handleSaveChanges,
    handleDeleteTracker,
    statusOptions,
    typeOptions,
    severityOptions,
    journeyOptions,
    researches, // Add this prop to receive the researches array
}) => {
    // Updated getResearchName function
    const getResearchName = (tracker) => {
        const research = researches?.find((r) => r.ResearchID === tracker.ResearchID);
        if (research) {
            return (
                <Link className="text-muted" to={`/research/${research.ResearchID}`} target="_blank" rel="noopener noreferrer">
                    Research {research.ResearchName}
                </Link>
            );
        }
        return "N/A";
    };

    const renderEditableTableCell = (tracker, field, options = null) => {
        const trackerData = trackerUpdates[tracker.TrackerID];

        if (!trackerData) return null;

        if (editingField.trackerId === tracker.TrackerID && editingField.field === field) {
            if (options) {
                return (
                    <select
                        value={trackerData[field]}
                        onChange={(event) => handleTrackerChange(event, tracker.TrackerID, field)}
                        onBlur={() => handleSaveChanges(tracker.TrackerID)}
                        className="form-select form-select-sm"
                    >
                        {options.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                );
            } else if (field === "description") {
                return (
                    <textarea
                        value={trackerData[field]}
                        onChange={(event) => handleTrackerChange(event, tracker.TrackerID, field)}
                        onBlur={() => handleSaveChanges(tracker.TrackerID)}
                        className="form-control form-control-sm"
                        rows="3"
                    />
                );
            } else {
                return (
                    <input
                        type="text"
                        value={trackerData[field]}
                        onChange={(event) => handleTrackerChange(event, tracker.TrackerID, field)}
                        onBlur={() => handleSaveChanges(tracker.TrackerID)}
                        className="form-control form-control-sm"
                    />
                );
            }
        } else {
            if (field === "name") {
                return (
                    <div>
                        <span
                            onClick={() => handleEditClick(tracker.TrackerID, field)}
                            style={{ cursor: "pointer", display: "inline-flex", alignItems: "center" }}
                        >
                            {trackerData[field]}
                        </span>
                        {researches && (
                            <small className="text-muted d-block" style={{ fontSize: "0.7em" }}>
                                (Source: {getResearchName(tracker)})
                            </small>
                        )}
                    </div>
                );
            } else {
                return (
                    <span
                        onClick={() => handleEditClick(tracker.TrackerID, field)}
                        style={{ cursor: "pointer", display: "inline-flex", alignItems: "center" }}
                    >
                        {field === "status" ? (
                            <span className={`badge ${STATUS_OPTIONS.find((status) => status.value === trackerData[field]).backgroundClass}`}>
                                {trackerData[field]}
                            </span>
                        ) : (
                            trackerData[field]
                        )}
                        {(field === "status" || field === "severity" || field === "journey" || field === "type") && (
                            <i className="bi bi-caret-down-fill text-muted ms-2" style={{ fontSize: "0.6em" }}></i>
                        )}
                    </span>
                );
            }
        }
    };

    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th style={{ width: "30%" }}>Description</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Severity</th>
                        <th>Journey</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {trackers.map((tracker) => (
                        <tr key={tracker.TrackerID}>
                            <td>{renderEditableTableCell(tracker, "name")}</td>
                            <td style={{ maxWidth: "300px", wordWrap: "break-word" }}>{renderEditableTableCell(tracker, "description")}</td>
                            <td>{renderEditableTableCell(tracker, "status", statusOptions)}</td>
                            <td>{renderEditableTableCell(tracker, "type", typeOptions)}</td>
                            <td>{renderEditableTableCell(tracker, "severity", severityOptions)}</td>
                            <td>{renderEditableTableCell(tracker, "journey", journeyOptions)}</td>
                            <td>
                                <button className="btn btn-sm btn-outline-danger" onClick={() => handleDeleteTracker(tracker.TrackerID)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TrackerTable;
