import { Link } from "react-router-dom";
import * as Icon from "react-feather";
function UserDropdown({ logout }) {
    return (
        <div className="dropdown-menu justify-content-center ">
            {/* <Link className="dropdown-item text-decoration-none" to="/">
                Home
            </Link> */}
            <Link className="dropdown-item text-decoration-none" to="/dashboard">
                Dashboard
            </Link>
            <Link className="dropdown-item text-decoration-none" to="/repository">
                Repository
            </Link>
            <div className="dropdown-divider"></div>
            <a role="button" className="dropdown-item text-decoration-none" onClick={logout}>
                Logout
            </a>
        </div>
    );
}

export default UserDropdown;
