import { useEffect, useRef } from "react";

export const FormGroup = ({
    type = "text",
    label,
    id,
    options,
    value,
    onChange,
    required,
    disabled,
    defaultValue,
    style,
    pleaseSelect,
    maxLength,
    minLength,
    maxDate, // Added minDate prop
    autoHeight = false,
}) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (autoHeight && textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [value]);

    const handleTextareaChange = (e) => {
        if (autoHeight && textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
        onChange(e);
    };
    return (
        <div className="form-group">
            <div className="form-floating">
                {type === "textarea" ? (
                    <textarea
                        defaultValue={defaultValue}
                        placeholder=""
                        className="form-control"
                        id={id}
                        style={style}
                        required={required}
                        disabled={disabled}
                        {...(maxLength ? { maxLength } : {})}
                        value={value} // added value prop
                        onChange={handleTextareaChange} // Use handleTextareaChange
                        ref={textareaRef} // Assign ref to textarea
                    />
                ) : type === "select" ? (
                    <select
                        defaultValue={defaultValue}
                        className="form-select"
                        id={id}
                        required={required}
                        value={value} // added value prop
                        onChange={onChange} // added onChange prop
                        disabled={disabled}
                    >
                        {pleaseSelect && (
                            <option value="" disabled>
                                Please select
                            </option>
                        )}
                        {options.map(({ value, text }) => (
                            <option value={value} key={value}>
                                {text}
                            </option>
                        ))}
                    </select>
                ) : (
                    <input
                        defaultValue={defaultValue}
                        type={type}
                        className="form-control"
                        id={id}
                        placeholder={label}
                        required={required}
                        value={value} // added value prop
                        onChange={onChange} // added onChange prop
                        disabled={disabled}
                        {...(maxLength ? { maxLength } : {})}
                        {...(minLength ? { minLength } : {})}
                        {...(type === "date" && maxDate ? { max: maxDate } : {})} // Conditionally apply minDate
                    />
                )}
                <label htmlFor={id}>{label}</label>
            </div>
        </div>
    );
};
