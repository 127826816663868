import React, { useState, useEffect } from "react";
import axios from "axios";
import MethodCard from "../../organism/Card/MethodCard";
import Layout from "../../templates/Layout";
import Skeleton from "react-loading-skeleton";
import ProductCard from "../../organism/Card/ProductCard";
// var sort = "";
const ProductList = () => {
    const [products, setProducts] = useState([]);
    useEffect(() => {
        getProducts();
    }, []);
    const getProducts = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
        setProducts(response.data.filter((method) => method.ResearchMethodFileID !== null));
    };
    return (
        <Layout>
            <div className="container-fluid" style={{ backgroundColor: "#FEFAF5" }}>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-6 d-flex align-self-center ">
                            <div>
                                <h3>Products</h3>
                                <p>Getting to know insight state in each product</p>
                            </div>
                        </div>
                        <div className="col-md-4 offset-md-2 float-end align-items-end">
                            <img src={"/assets/images/Our Team.png"} className="w-100 float-end d-block" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-3">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            {products.length > 0 ? (
                                <ProductCard products={products} />
                            ) : (
                                <>
                                    <div className="col-md-4">
                                        <Skeleton height={100}></Skeleton>
                                    </div>
                                    <div className="col-md-4">
                                        <Skeleton height={100}></Skeleton>
                                    </div>
                                    <div className="col-md-4">
                                        <Skeleton height={100}></Skeleton>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ProductList;
