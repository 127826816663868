import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setProductFilter } from "../../../redux/filterSlice";

const ProductFilter = ({ onFilterChange }) => {
    const dispatch = useDispatch();
    const { product } = useSelector((state) => state.filter);
    const [products, setProducts] = useState([]);
    const [sortedProducts, setSortedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const getProducts = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
        setProducts(response.data);
    };
    useEffect(() => {
        getProducts()
    }, []);

    const sortProducts = () => {
        const sorted = [...products].sort((a, b) => {
            const aChecked = product.includes(a.ProductID);
            const bChecked = product.includes(b.ProductID);
            return aChecked === bChecked ? 0 : aChecked ? -1 : 1;
        });
        setSortedProducts(sorted);
    };

    useEffect(() => {
        sortProducts();
    }, [products, product]);

    useEffect(() => {
        setSearchTerm("")
        onFilterChange();
    }, [product]);

    const handleProductChange = (event) => {
        const productId = event.target.getAttribute('productid');
        if (event.target.checked) {
            dispatch(setProductFilter([...product, parseInt(productId)]));
        } else {
            const newProduct = product.filter(id => id !== parseInt(productId));
            dispatch(setProductFilter(newProduct));
        }
        if (onFilterChange) {
            onFilterChange();
        }
    };
    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredProducts = sortedProducts.filter(product => product?.ProductName?.toLowerCase().includes(searchTerm?.toLowerCase()));

    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className={`accordion-button ${!product.length > 0 && "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target="#productPanel-collapse">Product</button>
            </h2>
            <div id="productPanel-collapse" className={`accordion-collapse collapse ${product.length > 0 && "show"}`}>
                <div className="find-container">
                    <input
                        type="text"
                        className="form-control find-input"
                        placeholder="Search product..."
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                    />
                </div>
                <div className="accordion-body scrollable-list">
                    {filteredProducts.map((pro, index) => (
                        <div className="form-check" key={pro.ProductID}>
                            <input checked={product?.includes(pro.ProductID)} className="form-check-input" onChange={handleProductChange} productid={pro.ProductID} type="checkbox" id={`productCheck-${pro.ProductID}`} />
                            <label className="form-check-label" htmlFor={`productCheck-${pro.ProductID}`}> {pro.ProductName}</label>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductFilter;
