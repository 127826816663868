import React, { useState } from "react";
import { useSelector } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import * as Icon from "react-feather";
import * as XLSX from "xlsx"; // Import the xlsx library

const TranscribeResult = ({ transcriptions, getTranscriptions, loading }) => {
    const [isUploading, setIsUploading] = useState(false);
    const navigate = useNavigate();
    const { user, isError, isLoading } = useSelector((state) => state.auth);

    const speakerColors = [
        { background: "#FFD9D3", color: "#F2726B" }, // SPEAKER_00
        { background: "#E84B3C", color: "#FFFFFF" }, // SPEAKER_01
        { background: "#D5C1F2", color: "#7564A8" }, // SPEAKER_02
        { background: "#5D4D99", color: "#FFFFFF" }, // SPEAKER_03
        { background: "#DBE7F0", color: "#7A94B7" }, // SPEAKER_04
        { background: "#7D7C83", color: "#FFFFFF" }, // SPEAKER_05
        { background: "#FFCEC1", color: "#F76C55" }, // SPEAKER_06
        { background: "#DB3D2E", color: "#FFFFFF" }, // SPEAKER_07
        { background: "#FFD797", color: "#CC8535" }, // SPEAKER_08
        { background: "#FF9C35", color: "#FFFFFF" }, // SPEAKER_09
        { background: "#9EFFC8", color: "#5F9869" }, // SPEAKER_10
        { background: "#2EAF57", color: "#FFFFFF" }, // SPEAKER_11
        { background: "#C2DEFF", color: "#6D91C7" }, // SPEAKER_12
        { background: "#4668E4", color: "#FFFFFF" }, // SPEAKER_13
    ];
    const getColorForSpeaker = (speaker) => {
        const speakerIndex = parseInt(speaker.replace("SPEAKER_", ""), 10);
        return speakerColors[speakerIndex % speakerColors.length];
    };
    const handleExportToExcel = (transcription) => {
        const formattedData = [];

        if (transcription.TranscribeResult?.result.data) {
            if (transcription.TranscribeResult.result.data.audio_transcribe) {
                transcription.TranscribeResult.result.data.audio_transcribe.seg_results.forEach((seg_result) => {
                    if (seg_result[2]) {
                        // Check if Text exists
                        formattedData.push({
                            Filename: transcription.TranscribeFilename,
                            Start: seg_result[0],
                            End: seg_result[1],
                            Text: seg_result[2],
                            CreatedAt: moment(transcription.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                        });
                    }
                });
            } else if (Array.isArray(transcription.TranscribeResult.result.data)) {
                transcription.TranscribeResult.result.data.forEach((seg_result) => {
                    if (seg_result[3]) {
                        // Check if Text exists
                        formattedData.push({
                            Filename: transcription.TranscribeFilename,
                            Speaker: seg_result[0],
                            Start: seg_result[1],
                            End: seg_result[2],
                            Text: seg_result[3],
                            CreatedAt: moment(transcription.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                        });
                    }
                });
            } else if (transcription.TranscribeResult.result.data.seg_results) {
                transcription.TranscribeResult.result.data.seg_results.forEach((seg_result) => {
                    if (seg_result[2]) {
                        // Check if Text exists
                        formattedData.push({
                            Filename: transcription.TranscribeFilename,
                            Start: seg_result[0],
                            End: seg_result[1],
                            Text: seg_result[2],
                            CreatedAt: moment(transcription.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                        });
                    }
                });
            }
        }

        // Ensure the sheet name is within 31 characters
        let sheetName = transcription.TranscribeFilename;
        if (sheetName.length > 31) {
            sheetName = sheetName.substring(0, 28) + "..."; // Truncate and add ellipsis if too long
        }

        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

        XLSX.writeFile(workbook, `${sheetName}_Transcription.xlsx`);
    };

    return (
        <div className="col-12">
            <hr />
            <h5 className="my-3 mt-0">Transcription result</h5>
            <div className="d-flex justify-content-end">
                <button type="button" onClick={getTranscriptions} disabled={loading} className="btn btn-outline-primary btn-sm my-3">
                    <Icon.RefreshCw className={loading ? "loader-icon" : ""} size={11} />
                    {loading ? " Loading..." : " Refresh"}
                </button>
                <a
                    href="https://wa.me/628115111901"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-danger text-decoration-none btn-sm my-3 ms-2"
                >
                    <Icon.AlertTriangle size={11} /> Report a Problem
                </a>
            </div>
            {loading ? (
                <>
                    <Skeleton className="mb-3" height={60} />
                    <Skeleton className="mb-3" height={60} />
                    <Skeleton className="mb-3" height={60} />
                </>
            ) : (
                <div className="accordion mb-3" id="accordionExample">
                    {transcriptions.map((transcription, index) => (
                        <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={`heading-${index}`}>
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-${index}`}
                                    aria-expanded="false"
                                    aria-controls={`collapse-${index}`}
                                >
                                    {transcription.TranscribeFilename}{" "}
                                    <span className="small text-muted ms-2">({moment(transcription.createdAt).fromNow()})</span>{" "}
                                    {!transcription.TranscribeResult && <Icon.RefreshCw className={"loader-icon ms-2"} size={11} />}
                                    <div className="small">
                                        {transcription.Diarization && (
                                            <span className="badge bg-light text-dark border ms-2 fw-normal">
                                                Diarization ({transcription.Num_speakers} speakers)
                                            </span>
                                        )}
                                        {transcription.Summarize && (
                                            <span className="badge bg-light text-dark border ms-2 fw-normal">Summarize</span>
                                        )}
                                    </div>
                                </button>
                            </h2>
                            <div
                                id={`collapse-${index}`}
                                className="accordion-collapse collapse"
                                aria-labelledby={`heading-${index}`}
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    {transcription.TranscribeResult ? (
                                        <div>
                                            <div className="d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    onClick={() => handleExportToExcel(transcription)}
                                                    className="btn btn-outline-success btn-sm mb-2 ms-2"
                                                >
                                                    <i class="bi bi-file-earmark-excel-fill"></i> Export to Excel
                                                </button>
                                            </div>
                                            <div
                                                id={`transcription-text-${index}`}
                                                className="border"
                                                style={{
                                                    maxHeight: "400px",
                                                    overflowY: "auto",
                                                    padding: "10px",
                                                }}
                                            >
                                                {transcription.TranscribeResult?.result.data ? (
                                                    // Case 1: Handling 'audio_transcribe' with 'seg_results'
                                                    transcription.TranscribeResult.result.data.audio_transcribe ? (
                                                        transcription.TranscribeResult.result.data.audio_transcribe.seg_results.map(
                                                            (seg_result, segIndex) => {
                                                                const formatTime = (seconds) => {
                                                                    const minutes = Math.floor(seconds / 60);
                                                                    const remainingSeconds = seconds % 60;
                                                                    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
                                                                };

                                                                return (
                                                                    <div key={segIndex}>
                                                                        <div className="small">
                                                                            <div className="badge bg-light border text-dark fw-normal">
                                                                                {formatTime(seg_result[0])} - {formatTime(seg_result[1])}
                                                                            </div>
                                                                        </div>
                                                                        <p>{seg_result[2]}</p>
                                                                        <hr />
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                    ) : // Case 2: Handling 'data' directly with speaker and transcription text
                                                    Array.isArray(transcription.TranscribeResult.result.data) ? (
                                                        transcription.TranscribeResult.result.data.map((seg_result, segIndex) => {
                                                            const speaker = seg_result[0];
                                                            const startTime = seg_result[1];
                                                            const endTime = seg_result[2];
                                                            const transcriptText = seg_result[3];
                                                            const speakerStyle = getColorForSpeaker(speaker);

                                                            return (
                                                                <>
                                                                    {transcriptText && (
                                                                        <div key={segIndex}>
                                                                            <div className="small">
                                                                                <div className="badge fw-normal" style={speakerStyle}>
                                                                                    {speaker}
                                                                                </div>
                                                                                <div className="badge bg-light border text-dark fw-normal ms-3">
                                                                                    {startTime} - {endTime}
                                                                                </div>
                                                                            </div>
                                                                            <p>{transcriptText}</p>
                                                                            <hr />
                                                                        </div>
                                                                    )}
                                                                </>
                                                            );
                                                        })
                                                    ) : // Case 3: Handling 'seg_results' directly within 'data'
                                                    transcription.TranscribeResult.result.data.seg_results ? (
                                                        transcription.TranscribeResult.result.data.seg_results.map((seg_result, segIndex) => {
                                                            const formatTime = (seconds) => {
                                                                const minutes = Math.floor(seconds / 60);
                                                                const remainingSeconds = seconds % 60;
                                                                return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
                                                            };

                                                            return (
                                                                <div key={segIndex}>
                                                                    <div className="small">
                                                                        <div className="badge bg-light border text-dark fw-normal">
                                                                            {formatTime(seg_result[0])} - {formatTime(seg_result[1])}
                                                                        </div>
                                                                    </div>
                                                                    <p>{seg_result[2]}</p>
                                                                    <hr />
                                                                </div>
                                                            );
                                                        })
                                                    ) : null
                                                ) : (
                                                    <p>No transcription data available.</p>
                                                )}
                                            </div>
                                            {transcription.TranscribeResult?.result.data?.summary_result && (
                                                <>
                                                    <hr></hr>
                                                    <h4>Summary</h4>
                                                    <p>{transcription.TranscribeResult?.result.data?.summary_result}</p>
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <>Processing... come back later</>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TranscribeResult;
