import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser, reset } from "../../../redux/authSlice";
import { Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nik, setNIK] = useState("");
    const [password, setPassword] = useState("");
    const { user, isError, isSuccess, isLoading, message } = useSelector((state) => state.auth);
    const Login = (e) => {
        e.preventDefault();
        if (nik && password) {
            dispatch(LoginUser({ nik, password }));
        } else {
            showToast("Please fill email and password", "error");
        }
    };

    useEffect(() => {
        if (user) {
            navigate("/dashboard");
        }
    }, []);

    useEffect(() => {
        if (user && isSuccess) {
            navigate("/dashboard");
        }
    }, [user, isSuccess, navigate]);
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 px-lg-5">
                        <div className="px-lg-5">
                            <div className="px-lg-5">
                                <Link to="/">
                                    <div className="container-logo">
                                        <img alt="logo" className="wrapper-logo" width={180} src="/assets/images/Researcher Hub 1.png" />
                                    </div>
                                </Link>

                                <h1 className="wrapper-title mt-5">Login</h1>
                                <div className="mt-5 pt-5">
                                    {isError && (
                                        <div className="alert alert-danger alert-dismissible fade show text-center" role="alert">
                                            {message}
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                    )}
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button
                                                className="nav-link active"
                                                id="nav-home-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-home"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-home"
                                                aria-selected="true"
                                            >
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src="https://companieslogo.com/img/orig/TLK-08f588e1.png?t=1660221222"
                                                        width={15}
                                                        className="me-2"
                                                    />
                                                    SSO
                                                </div>
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="nav-profile-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-profile"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-profile"
                                                aria-selected="false"
                                            >
                                                User
                                            </button>
                                        </div>
                                    </nav>
                                    <div className="tab-content mt-5" id="nav-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="nav-home"
                                            role="tabpanel"
                                            aria-labelledby="nav-home-tab"
                                        >
                                            <form onSubmit={Login}>
                                                <div className="mb-4">
                                                    <p className="mb-1">NIK</p>
                                                    <div className="control">
                                                        <input
                                                            type="text"
                                                            autoComplete="nik"
                                                            className="form-control"
                                                            value={nik}
                                                            onChange={(e) => setNIK(e.target.value)}
                                                            placeholder="NIK"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <p className="mb-1">Password</p>
                                                    <div className="control">
                                                        <input
                                                            type="password"
                                                            autoComplete="current-password"
                                                            className="form-control"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            placeholder="Password"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <button
                                                        type="submit"
                                                        className="w-100 btn btn-primary"
                                                        disabled={isLoading ? true : false}
                                                    >
                                                        {isLoading ? (
                                                            <div>
                                                                <span className="spinner-border spinner-border-sm"></span> Loading...
                                                            </div>
                                                        ) : (
                                                            "Login"
                                                        )}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            Coming Soon
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-none d-lg-block">
                        <img src={"/assets/images/RH-01-Login.png"} className="w-100"></img>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
