import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Dashboard from "../pages/user/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogOut, reset, getMe } from "../../redux/authSlice";
import { googleLogout } from "@react-oauth/google";

const DashSidebar = () => {
    const navigate = useNavigate();
    const { user, isError, isSuccess, isLoading, message } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const logout = () => {
        if (user) {
            dispatch(LogOut());
            dispatch(reset());
            // googleLogout();
        }
        navigate("/login");
    };
    return (
        <div className="col-md-3 mb-3">
            <div className="card">
                <div className="card-body text-center">
                    <div className="mb-3">
                        <img
                            className="img-thumbnail img-avatar"
                            alt="Profile Picture"
                            style={{ height: "fit-content" }}
                            src={
                                user?.picture
                                    ? `${user.picture}`
                                    : `https://www.gravatar.com/avatar/94fbd4ba2c7a477a297033bdc1c82dbc?s=120&amp;d=mm&amp;r=pg`
                            }
                        />
                    </div>
                    <div>
                        <p className="d-block fw-bold">{user?.name}</p>
                        <p>{user?.email}</p>
                    </div>
                </div>
                <ul className="profileNavigation list-unstyled">
                    <li className={window.location.pathname === "/dashboard" ? "active" : null}>
                        <Link to="/dashboard">Dashboard</Link>
                    </li>
                    {user?.researcher && (
                        <>
                            <hr></hr>
                            <li className={window.location.pathname === "/importantlinks" ? "active" : null}>
                                <Link to="/importantlinks">Important Links</Link>
                            </li>
                            <li>
                                <Link to={`/researcher/${user.researcher?.ResearcherID}`}>My Researcher Profile</Link>
                            </li>
                            <li className={window.location.pathname === "/tools" ? "active" : null}>
                                <Link to="/tools">Tools</Link>
                            </li>
                            {([
                                "Research Coordinator 1",
                                "Research Coordinator 2",
                                "Research Coordinator EWZ",
                                "Head of Researcher",
                                "ResearchOps Coordinator",
                            ].includes(user?.researcher.Role) ||
                                user.researcher.ResearcherName === "Adi Hanif Sedar") && (
                                <>
                                    <hr></hr>
                                    <li>
                                        <Link to={`/useraccess`}>Manage User Access</Link>
                                    </li>
                                    <li>
                                        <Link to={`/consent-manager`}>Manage Consent</Link>
                                    </li>
                                </>
                            )}
                        </>
                    )}

                    <hr></hr>
                    <li>
                        <a role="button" onClick={logout}>
                            Log out
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default DashSidebar;
