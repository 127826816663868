import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import jwt_decode from "jwt-decode";

export const getUserFromToken = () => {
    const token = localStorage.getItem("token");
    if (!token) {
        return null;
    }
    try {
        const decodedToken = jwt_decode(token);
        return decodedToken;
    } catch (error) {
        console.error("Error decoding JWT:", error);
        return null;
    }
};

const initialState = {
    user: getUserFromToken(),
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response && error.response.status === 401) {
            const token = localStorage.getItem("token");

            if (token) {
                // If user is logged in but receives a 401, logout
                await axios.post(`${process.env.REACT_APP_API_URL}/logout`);
                localStorage.removeItem("token");
            }

            // Store the current path
            localStorage.setItem("redirectPath", window.location.pathname);

            // Redirect to the login page
            window.location = "/";
        }
        return Promise.reject(error);
    }
);

export const LoginUser = createAsyncThunk("user/LoginUser", async (credential, thunkAPI) => {
    try {
        let response;
        console.log("yes");
        if (credential.nik && credential.password) {
            response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                nik: credential.nik,
                password: credential.password,
            });
        }
        localStorage.setItem("token", response.data.token);
        return response.data;
    } catch (error) {
        if (error.response) {
            const message = error.response.data.msg;
            return thunkAPI.rejectWithValue(message);
        }
    }
});

export const LogOut = createAsyncThunk("user/LogOut", async (_, thunkAPI) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/logout`);
        localStorage.removeItem("token");
        return response.data;
    } catch (error) {
        if (error.response) {
            const message = error.response.data.msg;
            return thunkAPI.rejectWithValue(message);
        }
    }
});

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(LoginUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(LoginUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload.user;
            // Set di action.payload.user karena structure nya mengandung token
            // Get the stored path or default to home
            const redirectPath = localStorage.getItem("redirectPath");
            localStorage.removeItem("redirectPath"); // Clean up
            if (redirectPath && redirectPath.length > 0 && redirectPath !== "/dashboard") {
                console.log("redirectPath", redirectPath);
                // Redirect to the stored path
                window.location = redirectPath;
            }
        });
        builder.addCase(LoginUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        });
        builder.addCase(LogOut.fulfilled, (state) => {
            state.user = null;
            state.isSuccess = false;
            localStorage.removeItem("redirectPath"); // Clear redirect path on logout
        });
        builder.addCase(LogOut.rejected, (state, action) => {
            state.isError = true;
            state.message = action.payload;
        });
    },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
