// src/components/ResearchTabs.js
import React from "react";

const ResearchTabs = ({ Research, formatText }) => {
    return (
        <div className="col-md-12">
            <ul className="nav nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
                {Research?.ResearchBackground && (
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link small active"
                            id="description-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#description"
                            type="button"
                            role="tab"
                            aria-controls="description"
                            aria-selected="true"
                        >
                            Background
                        </button>
                    </li>
                )}
                {Research?.ResearchObjective && (
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link small"
                            id="price-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#price"
                            type="button"
                            role="tab"
                            aria-controls="price"
                            aria-selected="false"
                        >
                            Objective
                        </button>
                    </li>
                )}
                {Research?.ResearchMethodology && (
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link small"
                            id="image-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#image"
                            type="button"
                            role="tab"
                            aria-controls="image"
                            aria-selected="false"
                        >
                            Methodology
                        </button>
                    </li>
                )}
                {Research?.ResearchSuggestedWayForward && (
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link small"
                            id="image-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#file"
                            type="button"
                            role="tab"
                            aria-controls="file"
                            aria-selected="false"
                        >
                            Suggested Way Forward
                        </button>
                    </li>
                )}
                {Research?.ResearchExecutiveSummary && (
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link small"
                            id="notify-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#notify"
                            type="button"
                            role="tab"
                            aria-controls="notify"
                            aria-selected="false"
                        >
                            Executive Summary
                        </button>
                    </li>
                )}
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                    {formatText(Research?.ResearchBackground)}
                </div>
                <div className="tab-pane fade" id="price" role="tabpanel" aria-labelledby="price-tab">
                    {formatText(Research?.ResearchObjective)}
                </div>
                <div className="tab-pane fade" id="image" role="tabpanel" aria-labelledby="image-tab">
                    {formatText(Research?.ResearchMethodology)}
                </div>
                <div className="tab-pane fade" id="file" role="tabpanel" aria-labelledby="file-tab">
                    {formatText(Research?.ResearchSuggestedWayForward)}
                </div>
                <div className="tab-pane fade" id="notify" role="tabpanel" aria-labelledby="notify-tab">
                    {formatText(Research?.ResearchExecutiveSummary)}
                </div>
            </div>
        </div>
    );
};

export default ResearchTabs;
