import { createSlice } from '@reduxjs/toolkit';

const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        tribe: [],
        product: [],
        method: [],
        keyword: "",
    },
    reducers: {
        setTribeFilter(state, action) {
            state.tribe = action.payload;
        },
        setProductFilter(state, action) {
            state.product = action.payload;
        },
        setMethodFilter(state, action) {
            state.method = action.payload;
        },
        setKeywordFilter(state, action) {
            state.keyword = action.payload;
        },
    },
});

export const { setTribeFilter, setProductFilter, setMethodFilter, setKeywordFilter } = filterSlice.actions;

export default filterSlice.reducer;