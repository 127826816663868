import MethodFilter from "../../molecules/ResearchFilter/MethodFilter";
import ProductFilter from "../../molecules/ResearchFilter/ProductFilter";
import TribeFilter from "../../molecules/ResearchFilter/TribeFilter";
function ResearchFilter({ onFilterChange }) {
    return (
        <>
            <div className="card sticky-top mb-3" style={{ top: "75px" }}>
                <div className="card-header">
                    <h6 className="mb-0 py-1">FILTER</h6>
                </div>
                <div className="card-body p-0">
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                        <ProductFilter onFilterChange={onFilterChange} />
                        <MethodFilter onFilterChange={onFilterChange} />
                        <TribeFilter onFilterChange={onFilterChange} />
                        {/* TODO LOCATION FILTER */}
                    </div>
                </div>
            </div>
        </>
    );
};
export default ResearchFilter;