import React from "react";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { STATUS_OPTIONS, TYPE_OPTIONS, SEVERITY_OPTIONS, JOURNEY_OPTIONS } from "../../../constants/trackerOptions.js";
const calculateResearchSummary = (research) => {
    const summary = {
        total: 0,
        completedPercentage: 0,
    };

    // Initialize counts for each status dynamically
    STATUS_OPTIONS.forEach((status) => {
        summary[status.value] = 0;
    });

    research.trackers.forEach((tracker) => {
        if (summary.hasOwnProperty(tracker.status)) {
            summary[tracker.status]++;
            if (tracker.status !== "Pending" && tracker.status !== "Rejected") {
                summary.total++;
            }
        }
    });

    if (summary.total > 0) {
        summary.completedPercentage = ((summary.Completed / summary.total) * 100).toFixed(2);
    }

    return summary;
};

const ResearchCard = ({ research, index }) => {
    const researchSummary = calculateResearchSummary(research);

    return (
        <div key={index} className="col-lg-4 col-12 col-sm-6 col-md-6 nounderline d-flex align-items-stretch mb-4">
            <div className="card nounderline card-body card-hoverable d-flex flex-column justify-content-between">
                <Link to={`/research/${research.ResearchID}`} target="_blank" className="d-flex flex-column h-100">
                    <p className="mb-0 small text-muted">Research</p>

                    <h6 className="mb-0">{research.ResearchName}</h6>
                    <div className="mt-auto">
                        <div className="d-flex justify-content-between my-2">
                            {STATUS_OPTIONS.map((status) => (
                                <div
                                    key={status.value}
                                    data-tooltip-id={`tooltip-${status.value}-research-${index}`}
                                    data-tooltip-content={status.text}
                                >
                                    <i className={`bi ${status.iconClass} ${status.colorClass}`}></i> {researchSummary[status.value]}
                                </div>
                            ))}
                        </div>
                        <div style={{ position: "relative" }}>
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                    role="progressbar"
                                    style={{ width: `${researchSummary.completedPercentage}%` }}
                                    aria-valuenow={researchSummary.completedPercentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                                <span
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        color: "#000",
                                    }}
                                >
                                    {researchSummary.completedPercentage}% Completed
                                </span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            {/* Tooltips */}
            {STATUS_OPTIONS.map((status) => (
                <ReactTooltip key={`tooltip-${status.value}-research-${index}`} id={`tooltip-${status.value}-research-${index}`} />
            ))}
        </div>
    );
};

export default ResearchCard;
