import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogOut, reset, getMe } from "../../redux/authSlice";


import * as Icon from "react-feather";
import axios from "axios";
import Slider from "react-slick";

const Methods = () => {
    const [methods, setMethods] = useState([]);
    useEffect(() => {
        getMethods();
    }, []);
    const getMethods = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/methods`);
        setMethods(response.data);
    };
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplaySpeed: 2500,
        autoplay: true,
        arrows: false,
    };
    return (
        <>
            {methods.length > 0 && (
                <div className="container-fluid" style={{ backgroundColor: "#FFF" }}>
                    <div className="container">
                        <div className="row py-5">
                            <div className="col-12">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <h5>Our Research Method</h5>
                                    <div className="py-3">
                                        <p color="tertiary500">
                                            the strategies, processes or techniques utilized in the collection of data or evidence for analysis
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pb-5">
                            <div className="col-12 col-md-6">
                                <h5>Survey</h5>
                                <div className="text-justify py-5">
                                    <p color="tertiary500">Lorem ipsum</p>
                                </div>
                                <p  color="primary500">
                                    Used by
                                </p>
                                <div className="d-block float-end py-5">
                                    <p  color="primary500">
                                        See details
                                        <Icon.ArrowRight size={16}></Icon.ArrowRight>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Methods;
