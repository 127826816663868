import React from "react";
import DashSidebar from "../../organism/DashSidebar";
import { Link } from "react-router-dom";
import Layout from "../../templates/Layout";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

const ImportantLinks = () => {
    const [importantlinks, setImportantLinks] = useState([]);
    const getImportantLinks = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/importantlinks`);
        setImportantLinks(response.data);
    };
    useEffect(() => {
        if (importantlinks.length === 0) {
            getImportantLinks();
        }

    })
    return (
        <Layout>
            <div className="container">
                <div className="row py-3">
                    <DashSidebar />
                    <div className="col-md-9">
                        <div className="card">
                            <div className="card-body ">
                                <h4>Important Links</h4>
                                {importantlinks.map((linkType, index) => (
                                    <>

                                        <div className="row my-3">
                                            <div className="col-12">
                                                <p >{linkType.title}</p>
                                            </div>
                                            {linkType.seclinks.map((link, index) => (
                                                <div className="col-md-3 align-items-stretch d-flex my-2 text-center">
                                                    <Link target="_blank" to={`${link.href}`} className="link-card card text-decoration-none">
                                                        <div className="card-body">
                                                            {/* <i className={`${link.icon} fa-2x text-center my-4`}></i> */}
                                                            <img src={linkType.image} className="w-25"></img>
                                                            <p className="card-title mb-0 mt-3">
                                                                <p >{link.title}</p>
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ImportantLinks;

