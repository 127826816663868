import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../redux/authSlice";
import filterReducer from '../redux/filterSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// const persistConfig = {
//   key: 'rh_repo_persist_filter',
//   storage,
//   blacklist: ['keyword']
// };
// const persistedReducer = persistReducer(persistConfig, filterReducer);

const store = configureStore({
  reducer: {
    auth: authReducer,
    filter: filterReducer,
  },
});

// export const persistor = persistStore(store);

export default store;