import React, { useState } from "react";
import DashSidebar from "../../organism/DashSidebar";
import * as Icon from "react-feather";
import Layout from "../../templates/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";

const UserAccess = () => {
    const dispatch = useDispatch();
    const { user, isError, isSuccess, isLoading, message } = useSelector((state) => state.auth);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const getUsers = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`);
        setUsers(response.data);
    };
    const updateUserPermission = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/permission`, {
            UserID: selectedUser.UserID,
            PermissionID: 4,
        });
        getUsers();
        setShowModal(false);
    };
    useEffect(() => {
        getUsers();
    }, []);
    const handleRowClick = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };
    return (
        <>
            <div>
                <Modal show={showModal} className={"d-block"} onClose={() => setShowModal(false)}>
                    <Modal.Header>Authorize</Modal.Header>
                    <Modal.Body>
                        <div className="px-4">
                            {selectedUser && (
                                <>
                                    <p>
                                        <strong>Name:</strong> {selectedUser.name}
                                    </p>
                                    <p>
                                        <strong>Email:</strong> {selectedUser.email}
                                    </p>
                                    <p>
                                        <strong>Request Access:</strong>{" "}
                                        {selectedUser.requestAccess ? <Icon.CheckCircle color="green" /> : <Icon.XCircle color="red" />}
                                    </p>
                                    {selectedUser.requestAccessText && <p>{selectedUser.requestAccessText}</p>}
                                </>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <>
                            <div style={{ display: "flex", justifyContent: "right" }}>
                                <button className="btn btn-light btn-sm me-2" onClick={() => setShowModal(false)}>
                                    Close
                                </button>
                                <button
                                    className="btn btn-primary btn-sm"
                                    css={{ marginLeft: "12px" }}
                                    onClick={() => {
                                        updateUserPermission();
                                    }}
                                >
                                    Authorize Access
                                </button>
                            </div>
                        </>
                    </Modal.Footer>
                </Modal>
            </div>
            <Layout>
                <div className="container">
                    <div className="row py-3">
                        <DashSidebar />
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-body">
                                    <h4>Manage User Access</h4>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">No.</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Request</th>
                                                <th scope="col">Granted</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((u, index) => (
                                                <tr key={index} onClick={() => handleRowClick(u)}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{u.name}</td>
                                                    <td>{u.email}</td>
                                                    <td title={`${u.requestAccessText}`}>
                                                        {u.requestAccess ? <Icon.CheckCircle color="green" /> : <Icon.XCircle color="red" />}
                                                    </td>
                                                    <td>
                                                        {u.researcher ||
                                                        u.permissions.some(
                                                            (permission) => permission.PermissionName === "Repository Access"
                                                        ) ? (
                                                            <Icon.CheckCircle color="green" />
                                                        ) : (
                                                            <Icon.XCircle color="red" />
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default UserAccess;
