import React, { useState, useEffect } from "react";
import axios from "axios";
import MethodCard from "../../organism/Card/MethodCard";
import Layout from "../../templates/Layout";
import Skeleton from "react-loading-skeleton";
// var sort = "";
const MethodList = () => {
    const [methods, setMethods] = useState([]);
    useEffect(() => {
        getMethods();
    }, []);
    const getMethods = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/methods`);
        setMethods(response.data.filter((method) => method.ResearchMethodFileID !== null));
    };
    return (
        <Layout>
            <div className="container-fluid" style={{ backgroundColor: "#FEFAF5" }}>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-6 d-flex align-self-center ">
                            <div>
                                <h3>Research Methods Guideline</h3>
                                <p>Getting to know our method guideline</p>
                            </div>
                        </div>
                        <div className="col-md-4 offset-md-2 float-end">
                            <img src={"/assets/images/Our Team.png"} className="w-100 float-end d-block" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-3">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            {methods.length > 0 ? (
                                <MethodCard col="4" methods={methods} />
                            ) : (
                                <>
                                    <div className="col-md-4">
                                        <Skeleton height={100}></Skeleton>
                                    </div>
                                    <div className="col-md-4">
                                        <Skeleton height={100}></Skeleton>
                                    </div>
                                    <div className="col-md-4">
                                        <Skeleton height={100}></Skeleton>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default MethodList;
