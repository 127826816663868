import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../templates/Layout.jsx";
import _ from "lodash";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import LibraryUpload from "../../molecules/LibraryUpload.js";
import TranscribeResult from "./TranscribeResult.js";
import { showToast } from "../../../utils/toastHelper.js";
import axios from "axios";

const TranscribeAudio = () => {
    const [transcriptions, setTranscriptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [mode, setMode] = useState("Web");
    const navigate = useNavigate();
    const { user, isError, isLoading } = useSelector((state) => state.auth);
    const getTranscriptions = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/transcriptions`);
            setTranscriptions(response.data);
        } catch (error) {
            showToast(`An error occured ${error.message}`, "error");
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getTranscriptions();
    }, []);
    return (
        <Layout>
            <div className="container">
                <div className="row py-3">
                    {user && !isLoading && (
                        <>
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body pb-0">
                                        <div className="row justify-content-center">
                                            <div className="col-md-8 py-3">
                                                <div className="w-100">
                                                    <h5 className="my-3 mt-0">Let's transcribe your interview session!</h5>
                                                    <LibraryUpload
                                                        accept={`audio/m4a, audio/mp3, video/mp4`}
                                                        isUploading={isUploading}
                                                        setIsUploading={setIsUploading}
                                                        mode={mode}
                                                        setMode={setMode}
                                                        getTranscriptions={getTranscriptions}
                                                    ></LibraryUpload>
                                                </div>
                                            </div>
                                            <div className="col-md-4 d-flex d-md-block d-none float-end align-items-end">
                                                <img src={"/assets/images/Research Repo.png"} className="w-100 float-end d-block" />
                                            </div>
                                            <TranscribeResult
                                                getTranscriptions={getTranscriptions}
                                                transcriptions={transcriptions}
                                                setTranscriptions={setTranscriptions}
                                                loading={loading}
                                                setLoading={setLoading}
                                            ></TranscribeResult>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default TranscribeAudio;
