const createEventSource = async (endpoint, params = {}) => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/${endpoint}`);
    Object.entries(params).forEach(([key, value]) => {
        url.searchParams.append(key, value);
    });

    const response = await fetch(url, { credentials: "include" });
    if (response.status === 429) {
        throw new Error(`${response.statusText}, please try again after 5 minutes`);
    }

    return new EventSource(url, { withCredentials: true });
};

export const sendPrompt = async (prompt, researchId) => {
    return createEventSource(`askai/${researchId}`, { prompt });
};

export const generateTracker = async (researchId) => {
    return createEventSource(`generateTracker/${researchId}`);
};

export const generateProblem = async (researchId) => {
    return createEventSource(`generateProblem/${researchId}`);
};

export const generateVerbatim = async (researchId) => {
    return createEventSource(`generateVerbatim/${researchId}`);
};
