import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import { showToast } from "../../../../utils/toastHelper";
import axios from "axios";
import ResearchCard from "../../../organism/Card/ResearchCard";
import Skeleton from "react-loading-skeleton";

const DashboardNonResearcher = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);
    const [researches, setResearches] = useState([]);
    const [loading, setLoading] = useState(false);

    const getRecentlyViewed = async () => {
        try {
            setLoading(true);
            const researchIds = await axios.get(`${process.env.REACT_APP_API_URL}/recentViewResearches`);
            if (researchIds.data && researchIds.data.length > 0) {
                const response = await axios.put(`${process.env.REACT_APP_API_URL}/researches`, {
                    TribeID: [],
                    ProductID: [],
                    Keyword: "",
                    MethodID: [],
                    ResearchID: researchIds.data,
                    Page: 1,
                });
                setResearches(response.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getGreeting = () => {
        const hours = new Date().getHours();
        if (hours < 12) return "Good Morning";
        if (hours < 18) return "Good Afternoon";
        return "Good Evening";
    };

    useEffect(() => {
        getRecentlyViewed();
    }, []);

    return (
        <>
            <div className="row justify-content-center">
                {user && user.nik && (
                    <>
                        <div className="d-flex align-items-center mb-3">
                            <img
                                src={`https://diariumsrv.telkom.co.id/getfoto/${user.nik}`}
                                alt="User"
                                className="border border-2 rounded-circle me-2"
                                style={{ width: "45px", height: "45px", objectFit: "cover" }}
                            />
                            <h5 className="mb-0">
                                {getGreeting()} {user?.nik}!
                            </h5>
                        </div>
                        <hr className="mb-0" />
                    </>
                )}
            </div>
            <div className="row justify-content-center">
                <div className="col-md-8 py-3 d-flex align-self-center text-justify">
                    <div className="w-100">
                        <h6>Research</h6>
                        <div onClick={() => navigate("/repository")} className="card mb-3 card-hoverable">
                            <div className="card-body">
                                <i class="bi bi-archive"></i> Access Repository
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                navigate("/products");
                            }}
                            className="card mb-3 card-hoverable"
                        >
                            <div className="card-body">
                                <i class="bi bi-check-circle"></i> Products UX Insight Status
                                <div className="small d-inline text-muted" style={{ fontSize: "12px" }}></div>
                                <span className="badge bg-danger float-end">New</span>
                            </div>
                        </div>
                        <hr></hr>
                        <h6>Tools</h6>
                        <div onClick={() => navigate("/transcribe")} className="card mb-3 card-hoverable">
                            <div className="card-body">
                                <i class="bi bi-chat-right-quote"></i> Transcribe Audio
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex flex-column justify-content-end">
                    <img src={"/assets/images/Research Repo.png"} className="w-100" style={{ objectFit: "contain", marginTop: "auto" }} />
                </div>
            </div>

            {!loading || (researches && researches.length > 0) ? (
                <div className="row">
                    <div className="col-12">
                        {researches.length > 0 && (
                            <>
                                <hr className="mt-0"></hr>
                                <h6 className="mb-3">Recently Viewed Research</h6>

                                <div className="col-12">
                                    <div className="row" id="researchlist">
                                        <ResearchCard col="4" researches={researches} />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-12">
                        <hr className="mt-0"></hr>
                        <h6 className="mb-3">Recently Viewed Research</h6>
                        <div className="row">
                            {[...Array(3)].map((_, index) => (
                                <div key={index} className="col-md-4">
                                    <Skeleton height={150} className="mb-3"></Skeleton>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DashboardNonResearcher;
