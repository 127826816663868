export const FormGroup = ({
    type = "text",
    label,
    id,
    options,
    value,
    onChange,
    required,
    disabled,
    defaultValue,
    style,
    pleaseSelect,
    maxLength,
    name,
    autoComplete,
}) => (
    <div className="form-group">
        <div className="form-floating">
            {type === "textarea" ? (
                <textarea
                    defaultValue={defaultValue}
                    placeholder=""
                    className="form-control"
                    id={id}
                    style={style}
                    required={required}
                    disabled={disabled}
                    maxLength={maxLength || ""}
                    value={value} // added value prop
                    onChange={onChange} // added onChange prop
                />
            ) : type === "select" ? (
                <select
                    defaultValue={defaultValue}
                    className="form-select"
                    id={id}
                    required={required}
                    value={value} // added value prop
                    onChange={onChange} // added onChange prop
                    disabled={disabled}
                >
                    {pleaseSelect && (
                        <option value="" disabled>
                            Please select
                        </option>
                    )}
                    {options.map(({ value, text }) => (
                        <option value={value} key={value}>
                            {text}
                        </option>
                    ))}
                </select>
            ) : (
                <input
                    defaultValue={defaultValue}
                    type={type}
                    className="form-control"
                    id={id}
                    placeholder={label}
                    required={required}
                    value={value} // added value prop
                    onChange={onChange} // added onChange prop
                    disabled={disabled}
                    maxLength={maxLength || null}
                    name={name}
                    autoComplete={autoComplete}
                />
            )}
            <label htmlFor={id}>{label}</label>
        </div>
    </div>
);
