import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormGroup } from "../../molecules/FormGroup";
import { generateTracker, generateProblem, generateVerbatim } from "../../../utils/promptHelper";
import { showToast } from "../../../utils/toastHelper";
import axios from "axios";
import { STATUS_OPTIONS, TYPE_OPTIONS, SEVERITY_OPTIONS, JOURNEY_OPTIONS } from "../../../constants/trackerOptions.js";

const ResearchAITools = ({ Research, getResearchById, supported }) => {
    const [promptResult, setPromptResult] = useState("");
    const [loadingSave, setLoadingSave] = useState(false);
    const [outputObjects, setOutputObjects] = useState(null);
    const [currentPromptType, setCurrentPromptType] = useState("");
    const { user } = useSelector((state) => state.auth);

    const handleTestPrompt = async (promptType) => {
        try {
            setPromptResult("Generating...");
            setOutputObjects(null);
            setCurrentPromptType(promptType); // Set the current prompt type
            let generateFunction;
            switch (promptType) {
                case "tracker":
                    generateFunction = generateTracker;
                    break;
                case "problem":
                    generateFunction = generateProblem;
                    break;
                case "verbatim":
                    generateFunction = generateVerbatim;
                    break;
                default:
                    throw new Error("Invalid prompt type");
            }
            const eventSource = await generateFunction(Research.ResearchID);
            let promptRes = "";
            eventSource.onmessage = (event) => {
                if (event.data) {
                    const data = JSON.parse(event.data);
                    if (data.choices[0].finish_reason) {
                        return eventSource.close();
                    }
                    const newPromptResult = data.choices[0].delta.content;

                    if (newPromptResult) {
                        promptRes = promptRes + newPromptResult;
                        setPromptResult(promptRes); // Update the state
                    }
                    try {
                        const parsedJSON = JSON.parse(promptRes);
                        parsedJSON.ResearchID = Research.ResearchID;
                        // Function to add ResearchID to each object in an array
                        const addStatus = (item) => ({
                            ...item,
                            status: "Pending",
                        });
                        if (parsedJSON.metrics) {
                            parsedJSON.metrics = parsedJSON.metrics.map(addStatus);
                        }
                        setOutputObjects(parsedJSON);
                    } catch (error) {}
                }
            };

            // Handle any error
            eventSource.onerror = (error) => {
                showToast("Error receiving data.", "error");
                setPromptResult("Error receiving data.");
                console.error("EventSource failed:", error);
                eventSource.close();
            };
        } catch (error) {
            showToast(`Error ${error.message}`, "error");
            setPromptResult("Error receiving data.");
            console.error(error);
        }
    };

    const handleFieldChange = (type, index, field, value) => {
        setOutputObjects((prevObjects) => {
            const newObjects = { ...prevObjects };
            if (type === "tracker") {
                newObjects.metrics[index][field] = value;
            } else if (type === "verbatim") {
                newObjects.quotes[index][field] = value;
            }
            return newObjects;
        });
    };

    const renderOutputObjects = () => {
        if (!outputObjects) return promptResult;
        if (currentPromptType === "tracker") {
            return outputObjects.metrics.map((metric, index) => (
                <div className="row" key={`tracker-${index}`}>
                    <div className="col-11">
                        <p className="fw-bold">{`Insight ${index + 1}`}</p>
                    </div>
                    <div className="col-1">
                        <i role="button" className="bi bi-trash3 text-danger" onClick={() => handleDeleteObject(index)}></i>
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-md-9 ">
                                <FormGroup
                                    type="text"
                                    label={`Insight Name`}
                                    value={metric.name}
                                    onChange={(e) => handleFieldChange("tracker", index, "name", e.target.value)}
                                />
                            </div>
                            <div className="col-md-3 ">
                                <FormGroup
                                    options={TYPE_OPTIONS}
                                    type="select"
                                    label={`Type`}
                                    value={metric.type}
                                    onChange={(e) => handleFieldChange("tracker", index, "type", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-9 ">
                                <FormGroup
                                    type="textarea"
                                    style={{ minHeight: "110px" }}
                                    label={`Description or Verbatim`}
                                    value={metric.description}
                                    onChange={(e) => handleFieldChange("tracker", index, "description", e.target.value)}
                                />
                            </div>
                            <div className="col-md-3">
                                <FormGroup
                                    options={STATUS_OPTIONS}
                                    type="select"
                                    label={`Status`}
                                    value={metric.status}
                                    onChange={(e) => handleFieldChange("tracker", index, "status", e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-6 mt-3">
                        <FormGroup
                            options={SEVERITY_OPTIONS}
                            type="select"
                            label={`Severity`}
                            value={metric.severity}
                            onChange={(e) => handleFieldChange("tracker", index, "severity", e.target.value)}
                        />
                    </div>
                    <div className="col-6 mt-3">
                        <FormGroup
                            options={JOURNEY_OPTIONS}
                            type="select"
                            label={`Journey`}
                            value={metric.journey}
                            onChange={(e) => handleFieldChange("tracker", index, "journey", e.target.value)}
                        />
                    </div>
                    <div className="col-12">
                        <hr className="my-3" />
                    </div>
                </div>
            ));
        } else if (currentPromptType === "verbatim") {
            return outputObjects.quotes.map((quote, index) => (
                <div className="row" key={`verbatim-${index}`}>
                    <div className="col-md-2">
                        <FormGroup type="text" label={`Initial`} value={quote.name} />
                    </div>
                    <div className="col-md-7">
                        <FormGroup type="textarea" style={{ minHeight: "90px" }} label={`Quote`} value={quote.quote} />
                    </div>
                    <div className="col-md-2">
                        <FormGroup type="text" label={`Type`} value={quote.type} />
                    </div>
                    <div className="col-md-1">
                        <i role="button" className="bi bi-trash3 text-danger" onClick={() => handleDeleteObject(index)}></i>
                    </div>
                    <div className="col-12">
                        <hr></hr>
                    </div>
                </div>
            ));
        }
    };

    const handleDeleteObject = (index) => {
        setOutputObjects((prevMetrics) => ({
            ...prevMetrics,
            metrics: prevMetrics.metrics.filter((_, i) => i !== index),
        }));
    };

    const handleSave = async () => {
        if (currentPromptType === "tracker" && outputObjects) {
            setLoadingSave(true);
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/trackers`, outputObjects);
                showToast("Metrics saved successfully!", "success");
                setOutputObjects(null);
                setPromptResult("");
                getResearchById();
            } catch (error) {
                showToast(`Error saving metrics. ${error.response?.data?.msg || error.message}`, "error");
                console.error("Error saving metrics:", error);
            } finally {
                setLoadingSave(false);
            }
        }
    };

    const handleAddObject = () => {
        const newObject =
            currentPromptType === "tracker"
                ? {
                      name: "",
                      type: "",
                      description: "",
                      status: "Pending",
                      classification: "Low Priority",
                  }
                : {
                      name: "",
                      quote: "",
                      type: "",
                  };

        setOutputObjects((prevObjects) => {
            const newObjects = { ...prevObjects };
            if (currentPromptType === "tracker") {
                newObjects.metrics.push(newObject);
            } else if (currentPromptType === "verbatim") {
                newObjects.quotes.push(newObject);
            }
            return newObjects;
        });
    };

    return (
        <>
            {supported && supported?.statusText !== "Forbidden" && supported?.details !== "No Google Presentation links found" && (
                <div className="col-12">
                    <button type="button" className="btn btn-outline-primary btn-sm me-2" onClick={() => handleTestPrompt("tracker")}>
                        Suggest Insight Tracker
                    </button>
                    <button type="button" className="btn btn-outline-primary btn-sm me-2" onClick={() => handleTestPrompt("verbatim")}>
                        Identify Verbatim
                    </button>
                    {promptResult && (
                        <>
                            <div className="row my-3 d-flex align-items-center">
                                <div className="col-lg-6">
                                    <label className="text-muted small">Suggested Result</label>
                                </div>
                                {currentPromptType === "tracker" && (
                                    <div className="col-lg-6">
                                        <button
                                            disabled={loadingSave}
                                            type="button"
                                            onClick={handleSave}
                                            className="btn btn-primary btn-sm float-end"
                                        >
                                            Save
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="p-3 small bg-light rounded" style={{ maxHeight: "500px", overflowY: "auto", overflowX: "clip" }}>
                                <p className="mb-0">{renderOutputObjects()}</p>
                                {outputObjects && (
                                    <p onClick={handleAddObject} role="button">
                                        <i class="bi bi-plus"></i>
                                        Add
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                    <hr></hr>
                </div>
            )}
        </>
    );
};

export default ResearchAITools;
