import React, { useState, useRef } from "react";
import * as Icon from "react-feather";
import axios from "axios";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { Dropzone } from "@dropzone-ui/react";
import { showToast } from "../../utils/toastHelper";
import { Modal, Button } from "react-bootstrap";

const LibraryUpload = ({ accept, isUploading, setIsUploading, mode, setMode, getTranscriptions }) => {
    const [isConverting, setIsConverting] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [convertProgress, setConvertProgress] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const ffmpegRef = useRef(new FFmpeg());
    const messageRef = useRef(null);
    const [showDiarizationModal, setShowDiarizationModal] = useState(false);
    const [showSummaryModal, setShowSummaryModal] = useState(false);
    const [numberOfSpeakers, setNumberOfSpeakers] = useState("");
    const [wantsDiarization, setWantsDiarization] = useState(false);
    const [wantsSummary, setWantsSummary] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);

    const loadFFmpeg = async () => {
        const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm";
        const ffmpeg = ffmpegRef.current;
        let duration = 0; // Initialize duration

        ffmpeg.on("log", ({ message }) => {
            console.log(message);

            // Extract the duration of the video file from the log
            if (message.includes("Duration:")) {
                const match = message.match(/Duration:\s(\d{2}):(\d{2}):(\d{2}).(\d{2})/);
                if (match) {
                    const hours = parseInt(match[1], 10);
                    const minutes = parseInt(match[2], 10);
                    const seconds = parseInt(match[3], 10);
                    const milliseconds = parseInt(match[4], 10);
                    duration = hours * 3600 + minutes * 60 + seconds + milliseconds / 100;
                }
            }

            // Calculate conversion progress
            if (message.includes("size=")) {
                const match = message.match(/time=(\d{2}:\d{2}:\d{2}.\d{2})/);
                if (match && duration > 0) {
                    const time = match[1];
                    const timeParts = time.split(":").map(parseFloat);
                    const totalSeconds = timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2];
                    const progress = Math.min(100, (totalSeconds / duration) * 100);
                    console.log("convertProgress", totalSeconds, duration, progress);
                    setConvertProgress(progress);
                }
            }
        });

        await ffmpeg.load({
            coreURL: `${baseURL}/ffmpeg-core.js`,
            wasmURL: `${baseURL}/ffmpeg-core.wasm`,
        });
        setLoaded(true);
    };

    const uploadFileToServer = async (formData) => {
        try {
            showToast("Uploading file...", "info");
            setIsUploading(true);
            setUploadProgress(0);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/transcribe`, formData, {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                },
            });

            showToast("File uploaded successfully. We will process your audio in the background. Please refresh regularly.", "success");
            setMode("Your Library");
        } catch (error) {
            console.error("Error uploading file", error);
            showToast(`Error uploading file: ${error.response?.data?.msg || error.message}`, "error");
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
            setConvertProgress(0);
            getTranscriptions();
        }
    };

    const convertToMp3 = async (file) => {
        const ffmpeg = ffmpegRef.current;
        if (!loaded) {
            await loadFFmpeg();
        }

        await ffmpeg.writeFile("input.mp4", await fetchFile(file));
        await ffmpeg.exec(["-i", "input.mp4", "output.mp3"]);
        const data = await ffmpeg.readFile("output.mp3");
        const mp3Blob = new Blob([data.buffer], { type: "audio/mp3" });
        const mp3File = new File([mp3Blob], file.name.replace(/\.[^/.]+$/, "") + ".mp3", { type: "audio/mp3" });

        return mp3File;
    };

    const handleChange = async (incomingFiles) => {
        const validExtensions = accept.split(",").map((type) => type.trim().split("/")[1]);

        if (incomingFiles.length > 0) {
            const file = incomingFiles[0].file;
            const fileExtension = file.name.split(".").pop().toLowerCase();
            if (validExtensions.includes(fileExtension)) {
                setCurrentFile(file);
                setShowDiarizationModal(true);
            } else {
                showToast(`Invalid file type. Accepted types are: ${validExtensions.join(", ")}`, "error");
            }
        }
    };

    const handleDiarizationConfirm = () => {
        setShowDiarizationModal(false);
        if (wantsDiarization) {
            // If diarization is wanted, proceed with upload
            handleUpload();
        } else {
            // If diarization is not wanted, show summary modal
            setShowSummaryModal(true);
        }
    };

    const handleSummaryConfirm = () => {
        setShowSummaryModal(false);
        handleUpload();
    };

    const handleUpload = async () => {
        let uploadFile = currentFile;
        const fileExtension = currentFile.name.split(".").pop().toLowerCase();

        // Convert MP4 to MP3 if necessary
        if (fileExtension === "mp4") {
            showToast("Converting MP4 to MP3. This might take a while. Please wait...", "info");
            setIsConverting(true);
            uploadFile = await convertToMp3(currentFile);
            setIsConverting(false);
        }

        // Prepare FormData and upload the file
        const formData = new FormData();
        formData.append("file", uploadFile);
        if (wantsDiarization) {
            formData.append("diarization", true);
            formData.append("num_speakers", numberOfSpeakers);
        } else if (wantsSummary) {
            formData.append("summarize", true);
        }

        uploadFileToServer(formData);
    };

    return (
        <>
            <div className="dropzone-wrapper">
                <Dropzone
                    role="button"
                    minHeight="160px"
                    onChange={handleChange}
                    accept={accept}
                    maxFiles={1}
                    maxFileSize={1000 * 1024 * 1024}
                    label="Click here to browse and upload files"
                    disabled={isUploading || isConverting}
                />
                {convertProgress > 0 && (
                    <div className="progress my-3">
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${convertProgress}%` }}
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            Converting video to audio... ({convertProgress.toFixed(2)}%)
                        </div>
                    </div>
                )}
                {isUploading && (
                    <div className="progress my-3">
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${uploadProgress}%` }}
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            Uploading to server... ({uploadProgress}%)
                        </div>
                    </div>
                )}
            </div>

            <Modal show={showDiarizationModal} onHide={() => setShowDiarizationModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="h5 mb-0">Diarization Options</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Would you like to differentiate between different speakers in the audio? This process is called diarization. Please
                        note that enabling diarization might <span className="text-danger">take longer</span> and could result in a{" "}
                        <span className="text-danger">DECREASE</span> in the quality of the transcription.
                    </p>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="wantsDiarization"
                            checked={wantsDiarization}
                            onChange={(e) => setWantsDiarization(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="wantsDiarization">
                            Enable Diarization
                        </label>
                    </div>
                    {wantsDiarization && (
                        <div className="mt-3">
                            <label htmlFor="numberOfSpeakers" className="form-label">
                                Number of Speakers:
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="numberOfSpeakers"
                                value={numberOfSpeakers}
                                onChange={(e) => setNumberOfSpeakers(e.target.value)}
                                min="1"
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDiarizationModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleDiarizationConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSummaryModal} onHide={() => setShowSummaryModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="h5 mb-0">Summary Options</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Would you like to summarize the transcription after it's completed? This will not affect the quality of the
                        transcription, and the summary will be concise.
                    </p>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="wantsSummary"
                            checked={wantsSummary}
                            onChange={(e) => setWantsSummary(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="wantsSummary">
                            Enable Summary
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSummaryModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSummaryConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LibraryUpload;

async function fetchFile(file) {
    const response = await fetch(URL.createObjectURL(file));
    const buffer = await response.arrayBuffer();
    return new Uint8Array(buffer);
}
