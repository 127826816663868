import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import "moment-timezone";
import { seoUrl } from "../../../utils/urlHelper";
function MethodCard(props) {
    return (
        <>
            {props.methods.map((method, index) => (
                <>
                    <div key={method} className={`col-${props.col} nounderline d-flex align-items-stretch`}>
                        <div variant="bordered" className="my-3 w-100 card card-hoverable" >
                            <Link to={`/method/${seoUrl(method.ResearchMethodName)}`}>
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-12 pl-0 d-flex align-self-center ">
                                            <div>
                                                <p>
                                                    <div
                                                        style={{
                                                            maxHeight: 67,
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: 3,
                                                            WebkitBoxOrient: "vertical",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        <i className={`fa-solid fa-${method.ResearchMethodIcon} text-muted mx-auto me-2`}></i>
                                                        {method.ResearchMethodName}
                                                    </div>
                                                </p>
                                                <p className="small text-muted">
                                                    <div
                                                        style={{
                                                            maxHeight: 47,
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: 2,
                                                            WebkitBoxOrient: "vertical",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                        className="mt-1"
                                                    >
                                                        {method.ResearchMethodShortDesc}
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </>
            ))}
        </>
    );
}
export default MethodCard;
