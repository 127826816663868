export const TYPE_OPTIONS = [
    { value: "UI", text: "UI" },
    { value: "UX", text: "UX" },
    { value: "UI & UX", text: "UI & UX" },
    { value: "UX Writing", text: "UX Writing" },
    { value: "Business", text: "Business" },
    { value: "Tech", text: "Tech" },
    { value: "Feature Request", text: "Feature Request" },
];

export const STATUS_OPTIONS = [
    {
        value: "Pending",
        text: "Pending",
        iconClass: "bi-hourglass-bottom",
        colorClass: "text-warning",
        backgroundColor: "#FFF3CD",
        backgroundClass: "bg-warning",
        borderColor: "#FFEEBA",
        description: "Insight is awaiting review or action",
        textColor: "#856404",
        backgroundClassColor: "#ffc107", // Bootstrap warning color
    },
    {
        value: "Backlog",
        text: "Backlog",
        iconClass: "bi-hourglass-split",
        colorClass: "text-secondary",
        backgroundColor: "#E9ECEF",
        backgroundClass: "bg-secondary",
        borderColor: "#CED4DA",
        description: "Insight is logged but not currently being addressed",
        textColor: "#6C757D",
        backgroundClassColor: "#6c757d", // Bootstrap secondary color
    },
    {
        value: "In-Progress",
        text: "In Progress",
        iconClass: "bi-arrow-repeat",
        colorClass: "text-primary",
        backgroundColor: "#CCE5FF",
        backgroundClass: "bg-primary",
        borderColor: "#B8DAFF",
        description: "Work on the insight has started",
        textColor: "#004085",
        backgroundClassColor: "#007bff", // Bootstrap primary color
    },
    {
        value: "In-Development",
        text: "In Development",
        iconClass: "bi-tools",
        colorClass: "text-info",
        backgroundColor: "#D1ECF1",
        backgroundClass: "bg-info",
        borderColor: "#BEE5EB",
        description: "Insight is being implemented or developed",
        textColor: "#0C5460",
        backgroundClassColor: "#17a2b8", // Bootstrap info color
    },
    {
        value: "On-Hold",
        text: "On Hold",
        iconClass: "bi-pause-circle",
        colorClass: "text-muted",
        backgroundColor: "#E2E3E5",
        backgroundClass: "bg-secondary",
        borderColor: "#D6D8DB",
        description: "Work on the insight has been temporarily paused",
        textColor: "#383D41",
        backgroundClassColor: "#6c757d", // Bootstrap secondary color
    },
    {
        value: "Completed",
        text: "Completed",
        iconClass: "bi-check-circle-fill",
        colorClass: "text-success",
        backgroundColor: "#D4EDDA",
        backgroundClass: "bg-success",
        borderColor: "#C3E6CB",
        description: "Insight has been fully addressed and implemented",
        textColor: "#155724",
        backgroundClassColor: "#28a745", // Bootstrap success color
    },
    {
        value: "Rejected",
        text: "Rejected",
        iconClass: "bi-x-circle-fill",
        colorClass: "text-danger",
        backgroundColor: "#F8D7DA",
        backgroundClass: "bg-danger",
        borderColor: "#F5C6CB",
        description: "Insight has been reviewed and not approved for action",
        textColor: "#721C24",
        backgroundClassColor: "#dc3545", // Bootstrap danger color
    },
];

export const SEVERITY_OPTIONS = [
    { value: "Cosmetic", text: "Cosmetic", backgroundColor: "#4CAF50" }, // Green
    { value: "Minor", text: "Minor", backgroundColor: "#FFC107" }, // Yellow
    { value: "Major", text: "Major", backgroundColor: "#FF9800" }, // Orange
    { value: "Catastrophe", text: "Catastrophe", backgroundColor: "#F44336" }, // Red
];

export const JOURNEY_OPTIONS = [
    { value: "Explore", text: "Explore" },
    { value: "Buy", text: "Buy" },
    { value: "Pay", text: "Pay" },
    { value: "Activate", text: "Activate" },
    { value: "Use", text: "Use" },
    { value: "Get Support", text: "Get Support" },
    { value: "Terminate", text: "Terminate" },
];
