import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setTribeFilter } from "../../../redux/filterSlice";

const TribeFilter = ({ onFilterChange }) => {
    const dispatch = useDispatch();
    const { tribe } = useSelector((state) => state.filter);
    const [tribes, setTribes] = useState([]);
    const [sortedTribes, setSortedTribes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const getCategories = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/tribes`);
        setTribes(response.data);
    };
    useEffect(() => {
        getCategories()
    }, []);

    const sortTribes = () => {
        const sorted = [...tribes].sort((a, b) => {
            const aChecked = tribe.includes(a.TribeID);
            const bChecked = tribe.includes(b.TribeID);
            return aChecked === bChecked ? 0 : aChecked ? -1 : 1;
        });
        setSortedTribes(sorted);
    };

    useEffect(() => {
        sortTribes();
    }, [tribes, tribe]);

    useEffect(() => {
        setSearchTerm("")
        onFilterChange();
    }, [tribe]);

    const handleTribeChange = (event) => {
        const tribeId = event.target.getAttribute('tribeid');
        if (event.target.checked) {
            dispatch(setTribeFilter([...tribe, tribeId]));
        } else {
            const newTribe = tribe.filter(id => id !== tribeId);
            dispatch(setTribeFilter(newTribe));
        }
        if (onFilterChange) {
            onFilterChange();
        }
    };
    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredTribes = sortedTribes.filter(tribe => tribe.TribeID.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className={`accordion-button ${!tribe.length > 0 && "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target="#tribePanel-collapse">Tribe</button>
            </h2>
            <div id="tribePanel-collapse" className={`accordion-collapse collapse ${tribe.length > 0 && "show"}`}>
                <div className="find-container">
                    <input
                        type="text"
                        className="form-control find-input"
                        placeholder="Search tribe..."
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                    />
                </div>
                <div className="accordion-body scrollable-list">
                    {filteredTribes.map((tri, index) => (
                        <div className="form-check" key={tri.TribeID}>
                            <input checked={tribe?.includes(tri.TribeID)} className="form-check-input" onChange={handleTribeChange} tribeid={tri.TribeID} type="checkbox" id={`tribeCheck-${tri.TribeID}`} />
                            <label className="form-check-label" htmlFor={`tribeCheck-${tri.TribeID}`}> {tri.TribeID} ({tri.TribeName})</label>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TribeFilter;
