import React from "react";
import Navbar from "../organism/Navbar";
import Footer from "../organism/Footer";

const Layout = ({ children }) => {
    return (
        <React.Fragment>
            <Navbar />
            {children}
            <Footer />
        </React.Fragment>
    );
};

export default Layout;