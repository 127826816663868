import React, { useEffect, useState } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { showToast } from "../../../utils/toastHelper";
import * as Icon from "react-feather";

const ResearchEmbedReport = ({ Research }) => {
    const [pdfData, setPdfData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [embedLinks, setEmbedLinks] = useState([]);

    const fetchContent = async () => {
        try {
            const embedLinks = Research.researchlinkdetails.filter(
                (link) => link.LinkURL && link.LinkID === 1 && (link.LinkURL.includes("presentation") || link.LinkURL.includes("document"))
            );

            const urlPromises = embedLinks.map(async (link) => {
                const encodedLink = encodeURIComponent(link.LinkURL);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-embed/${encodedLink}`, {
                    responseType: "blob",
                });
                const url = URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
                return { embedUrl: `${url}#zoom=page-width&navpanes=0`, originalUrl: link.LinkURL };
            });

            const urls = await Promise.all(urlPromises);
            setPdfData(urls);
        } catch (error) {
            console.error("Error fetching content:", error);
            showToast("Error loading PDF content", "error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Research) {
            const links = Research.researchlinkdetails.filter(
                (link) => link.LinkURL && link.LinkID === 1 && (link.LinkURL.includes("presentation") || link.LinkURL.includes("document"))
            );
            setEmbedLinks(links);
            if (pdfData.length === 0) {
                fetchContent();
            }
        }
    }, [Research]);

    const renderButtons = () =>
        embedLinks.map((link, index) => (
            <div key={index} className="d-flex justify-content-end pb-3">
                <a className="text-decoration-none" href={link.LinkURL} target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-primary btn-sm" size="sm">
                        <i className="bi bi-box-arrow-up-right me-1" style={{ fontSize: 12 }}></i> Report {index + 1}
                    </button>
                </a>
            </div>
        ));

    if (loading) {
        return (
            <>
                <Skeleton></Skeleton>
                <Skeleton height={350} className="mb-3"></Skeleton>
                {renderButtons()}
            </>
        );
    }

    if (pdfData.length === 0) {
        return (
            <div className="text-center">
                <p>There are no Google Slides found to display for this research. Take a look at the links on the right.</p>
            </div>
        );
    }

    return (
        <div>
            {pdfData.map((data, index) => (
                <div key={index}>
                    <iframe src={data.embedUrl} width="100%" height="600px" />
                    {renderButtons()[index]}
                </div>
            ))}
        </div>
    );
};

export default ResearchEmbedReport;
