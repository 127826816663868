import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { seoUrl } from "../../../utils/urlHelper";

const ResearchSideBar = ({ Research }) => {
    const { user } = useSelector((state) => state.auth);
    return (
        <>
            <div className="col-md-3 nounderline mb-2">
                {user &&
                    Research?.researchlinkdetails?.filter(
                        (link) =>
                            link.LinkURL != null &&
                            link.LinkID === 1 &&
                            (!String(link.LinkURL).includes("presentation") && !String(link.LinkURL).includes("document"))
                    ).length > 0 && (
                        <div className="card mb-2">
                            <div className="card-body">
                                <h6>Links</h6>
                                {Research?.researchlinkdetails
                                    ?.filter(
                                        (link) =>
                                            link.LinkURL != null &&
                                            link.LinkID === 1 &&
                                            (!String(link.LinkURL).includes("presentation") && !String(link.LinkURL).includes("document"))
                                    )
                                    .map((link, index) => (
                                        <a key={index} href={link.LinkURL} className="panel-hover" target="_blank">
                                            <div className="row p-3" style={{ borderTop: "0.5px solid #ddd" }}>
                                                <div className="col-2 p-0 ">
                                                    <i className={`${link.link.LinkIcon} text-muted mx-auto fa-2x`} />
                                                </div>
                                                <div className="col-8">
                                                    <p>Link {index + 1}</p>
                                                </div>
                                                <div className="col-2 small">
                                                    <i className="bi bi-box-arrow-up-right" />
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                            </div>
                        </div>
                    )}
                {Research?.researchmethods?.filter((method) => method.ResearchMethodName !== "Unspecified").length > 0 && (
                    <div className="card mb-2">
                        <div className="card-body">
                            <h6>Research Method</h6>
                            {Research?.researchmethods?.map(
                                (method, index) =>
                                    method.ResearchMethodName !== "Unspecified" && (
                                        <Link key={index} to={`/method/${seoUrl(method.ResearchMethodName)}`} className="panel-hover">
                                            <div className="row justify-content-center p-3" style={{ borderTop: "0.5px solid #ddd" }}>
                                                <div className="p-0 col-10 align-self-center">
                                                    <i className={`fa-solid fa-${method.ResearchMethodIcon} text-muted mx-auto me-2`}></i>
                                                    <span>{method.ResearchMethodName}</span>
                                                </div>
                                                <div className="col-2 align-self-center small">
                                                    <i className="bi bi-box-arrow-up-right" />
                                                </div>
                                                <div className="mt-2 col-12 p-0 ">
                                                    <p className="m-0 p-0 small desc text-justify">{method.ResearchMethodShortDesc}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                            )}
                        </div>
                    </div>
                )}
                {Research?.researchers?.length > 0 && (
                    <div className="card mb-2">
                        <div className="card-body">
                            <h6>Researcher</h6>
                            {Research?.researchers?.map((researcher, index) => (
                                <Link key={index} to={`/researcher/${researcher.ResearcherID}`} className="panel-hover">
                                    <div className="row justify-content-center p-3" style={{ borderTop: "0.5px solid #ddd" }}>
                                        <div className="col-10 align-self-center ">
                                            <p className="mb-0">{researcher.ResearcherName}</p>
                                        </div>
                                        <div className="col-2 align-self-center small">
                                            <i className="bi bi-box-arrow-up-right" />
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default ResearchSideBar;
