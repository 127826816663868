import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate, useLocation } from "react-router-dom";
import Login from "./components/pages/user/Login";
import Dashboard from "./components/pages/user/Dashboard";
import ResearchList from "./components/pages/research/List";
import AddResearch from "./components/pages/research/Add";
import EditResearch from "./components/pages/research/Edit";
import Research from "./components/pages/research/View";
import ResearcherList from "./components/pages/researcher/List";
import Researcher from "./components/pages/researcher/View";
import Home from "./components/pages/home.js";
import ImportantLinks from "./components/pages/user/ImportantLinks";
import Method from "./components/pages/method/View";
import MethodList from "./components/pages/method/List";
import UserAccess from "./components/pages/user/UserAccess";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Tools from "./components/pages/user/Tools";
import Consent from "./components/pages/research/Consent";
import TranscribeAudio from "./components/pages/user/TranscribeAudio";
import Product from "./components/pages/product/View";
import ProductList from "./components/pages/product/List";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <div>
            <GoogleOAuthProvider clientId="921579336648-qlvsmsf45lo35bv5cnd15h2dhv5h3b1l.apps.googleusercontent.com">
                <BrowserRouter>
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="repository/" element={<ResearchList />} />
                        {/* <Route path="researchers/" element={<ResearcherList />} /> */}
                        <Route path="useraccess/" element={<UserAccess />} />
                        <Route path="add" element={<AddResearch />} />
                        <Route path="edit/:id" element={<EditResearch />} />
                        <Route path="research/:id" element={<Research />} />
                        <Route path="product/:id" element={<Product />} />
                        <Route path="researcher/:id" element={<Researcher />} />
                        <Route path="method/:id" element={<Method />} />
                        <Route path="methods/" element={<MethodList />} />
                        <Route path="products/" element={<ProductList />} />
                        <Route path="login" element={<Login />} />
                        <Route path="importantlinks/" element={<ImportantLinks />} />
                        <Route path="tools" element={<Tools />} />
                        <Route
                            path="guideline/"
                            element={
                                <ExternalLink to="https://docs.google.com/presentation/d/11fE5W_JcUKMlIHnKA6AVdm0DFj_C1XZFgaUmxN3rY5E/edit?usp=sharing" />
                            }
                        />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="transcribe" element={<TranscribeAudio />} />
                        <Route path="consent" element={<Consent />} />
                    </Routes>
                </BrowserRouter>
            </GoogleOAuthProvider>
        </div>
    );
}
const ExternalLink = ({ to }) => {
    useEffect(() => {
        window.location.href = to;
    }, [to]);
    return null;
};
export default App;
