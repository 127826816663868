import React from "react";
import { useSelector } from "react-redux";
import DashSidebar from "../../organism/DashSidebar.js";
import Layout from "../../templates/Layout.jsx";
import _ from "lodash";
import "react-loading-skeleton/dist/skeleton.css";
import DashboardResearcher from "./Dashboard/Researcher.js";
import DashboardNonResearcher from "./Dashboard/NonResearcher.js";
import DashboardStrategic from "./Dashboard/Strategic.js";
import checkPermission from "../../../utils/permissionHelper.js";

export const researcherDashboard = ["UX Researcher", "ResearchOps Coordinator", "Researcher Coordinator 1", "Researcher Coordinator 2"];
const strategicDashboard = [
    "UX Researcher",
    "ResearchOps Coordinator",
    "RH Administrator",
    "Researcher Coordinator 1",
    "Researcher Coordinator 2",
    "ResearchOps",
    "Head of Researcher",
];
const Dashboard = () => {
    const { user, isError, isLoading } = useSelector((state) => state.auth);

    return (
        <Layout>
            <div className="container">
                <div className="row py-3 mb-5">
                    {user && !isLoading && (
                        <>
                            {/* <DashSidebar /> */}
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body pb-0">
                                        {/* <h5>Hi {user?.name?.split(" ")[0]} !</h5> */}
                                        {/* {user?.researcher && researcherDashboard.includes(user.researcher.Role) ? */}
                                        {/* <DashboardResearcher /> */}
                                        {/* : */}
                                        <DashboardNonResearcher />
                                        {/* } */}
                                        {/* {((user?.researcher && strategicDashboard.includes(user.researcher.Role)) || (checkPermission(user, "Strategic"))) &&
                      <DashboardStrategic />
                    } */}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;
