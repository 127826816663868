import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip"; // Import Tooltip component
import { STATUS_OPTIONS, TYPE_OPTIONS, SEVERITY_OPTIONS, JOURNEY_OPTIONS } from "../../../constants/trackerOptions.js";

function calculateResearchSummary(trackerStatusCounts) {
    const summary = {
        total: 0,
        completedPercentage: 0,
    };

    // Initialize the summary for each status in STATUS_OPTIONS
    STATUS_OPTIONS.forEach((status) => {
        summary[status.value] = 0;
    });

    trackerStatusCounts.forEach((status) => {
        if (summary.hasOwnProperty(status.status)) {
            summary[status.status] += status.count;
        }
        // Only count non-Pending and non-Rejected statuses for the total
        if (status.status !== "Pending" && status.status !== "Rejected") {
            summary.total += status.count;
        }
    });

    if (summary.total > 0) {
        summary.completedPercentage = ((summary.Completed / summary.total) * 100).toFixed(2);
    }

    return summary;
}

function ProductCard(props) {
    return (
        <>
            {props.products
                .filter((product) => product.trackerStatusCounts && product.trackerStatusCounts.length > 0)
                .map((product, index) => {
                    const researchSummary = calculateResearchSummary(product.trackerStatusCounts);
                    return (
                        <div key={index} className={`col-lg-4 col-12 col-sm-6 col-md-6 nounderline d-flex align-items-stretch`}>
                            <div className="my-3 w-100 card card-hoverable">
                                <Link to={`/product/${product.ProductID}`}>
                                    <div className="card-body">
                                        <div className="row justify-content-center">
                                            <div className="col-12 pl-0">
                                                <div
                                                    style={{
                                                        maxHeight: 67,
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: 3,
                                                        WebkitBoxOrient: "vertical",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {product.ProductName}
                                                </div>
                                                <div className="d-flex justify-content-between mt-3">
                                                    {STATUS_OPTIONS.map((status) => (
                                                        <div
                                                            key={status.value}
                                                            data-tooltip-id={`tooltip-${status.value}-${index}`}
                                                            data-tooltip-content={status.text}
                                                        >
                                                            <i className={`bi ${status.iconClass} ${status.colorClass}`}></i>{" "}
                                                            {researchSummary[status.value]}
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="mt-2" style={{ position: "relative" }}>
                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                                            role="progressbar"
                                                            style={{ width: `${researchSummary.completedPercentage}%` }}
                                                            aria-valuenow={researchSummary.completedPercentage}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                        ></div>
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                left: "50%",
                                                                transform: "translate(-50%, -50%)",
                                                                color: `#000`,
                                                            }}
                                                        >
                                                            {researchSummary.completedPercentage}% Completed
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* Tooltips */}
                            {STATUS_OPTIONS.map((status) => (
                                <Tooltip key={`tooltip-${status.value}-${index}`} id={`tooltip-${status.value}-${index}`} />
                            ))}
                        </div>
                    );
                })}
        </>
    );
}

export default ProductCard;
