import React, { useMemo } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { STATUS_OPTIONS } from "../../../constants/trackerOptions";

const TrackerProgressBar = ({ trackers }) => {
    const statusCounts = useMemo(() => {
        return trackers.reduce((acc, tracker) => {
            acc[tracker.status] = (acc[tracker.status] || 0) + 1;
            return acc;
        }, {});
    }, [trackers]);

    const totalIssues = useMemo(() => {
        return trackers.length - (statusCounts["Pending"] || 0) - (statusCounts["Rejected"] || 0);
    }, [trackers, statusCounts]);

    const completedIssues = statusCounts["Completed"] || 0;
    const completionPercentage = totalIssues > 0 ? (completedIssues / totalIssues) * 100 : 0;

    const renderTooltip = (status, count, percentage) => (
        <Tooltip id={`tooltip-${status}`}>
            {status}: {count}/{totalIssues} ({percentage.toFixed(1)}%)
        </Tooltip>
    );

    return (
        <div className="progress my-1" style={{ height: "10px" }}>
            {STATUS_OPTIONS.filter((status) => status.value !== "Rejected").map((status) => {
                const count = statusCounts[status.value] || 0;
                const percentage = totalIssues > 0 ? (count / totalIssues) * 100 : 0;
                return (
                    <OverlayTrigger
                        key={status.value}
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(status.value, count, percentage)}
                    >
                        <div
                            className={`progress-bar ${status.backgroundClass}`}
                            role="progressbar"
                            style={{
                                width: `${percentage}%`,
                                transition: "width 0.5s ease-in-out",
                            }}
                            aria-valuenow={percentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        />
                    </OverlayTrigger>
                );
            })}
        </div>
    );
};

export default TrackerProgressBar;
