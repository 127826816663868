import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import * as Icon from "react-feather";
import _ from "lodash";
import Layout from "../../templates/Layout";
import ResearchCard from "../../organism/Card/ResearchCard";

const Method = () => {
    const { user } = useSelector((state) => state.auth);
    const { id: ResearchMethodName } = useParams();
    const [Method, setMethod] = useState();
    const [researches, setResearches] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getMethod();
    }, []);
    useEffect(() => {
        if (Method && user) {
            getResearches();
        }
    }, [Method]);
    const getMethod = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/method/${ResearchMethodName}`);
        setMethod(response.data);
    };
    const getResearches = async () => {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/researches`, {
            TribeID: [],
            ProductID: [],
            Keyword: "",
            MethodID: [Method.ResearchMethodName],
            Page: 1,
        });
        setResearches(response.data);
    };
    return (
        <Layout>
            <div className="container">
                {/* <a role="button" onClick={() => navigate(-1)}>
                    <div className="d-flex align-items-center py-3">
                        <Icon.ArrowLeft size={15} />
                        <div>&nbsp;Back</div>
                    </div>
                </a> */}
                {Method && (
                    <div className="row py-5">
                        <div className={Method.ResearchMethodFileID ? `col-md-9 pb-3` : `col-12 pb-3`}>
                            <div className="card">
                                <div className="card-body">
                                    <h5>Research Method: {Method.ResearchMethodName}</h5>
                                    {Method.ResearchMethodLongDesc && (
                                        <>
                                            <hr></hr>
                                            {Method.ResearchMethodLongDesc}
                                        </>
                                    )}
                                    <hr></hr>
                                    {Method.ResearchMethodFileID ? (
                                        <iframe
                                            src={`https://drive.google.com/file/d/${Method.ResearchMethodFileID}/preview`}
                                            width="100%"
                                            height="720"
                                            allowFullScreen={true}
                                            mozallowfullscreen="true"
                                            webkitallowfullscreen="true"
                                        ></iframe>
                                    ) : (
                                        <div className="text-center text-muted small">There are no playbook for this method</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {Method.ResearchMethodFileID && (
                            <div className="col-md-3 nounderline mb-2">
                                <div className="card mb-2">
                                    <div className="card-body">
                                        <a
                                            href={`https://drive.google.com/uc?id=${Method.ResearchMethodFileID}&export=download`}
                                            className="panel-hover"
                                            target="_blank"
                                        >
                                            <div className="row justify-content-center p-3">
                                                <div className="col-2 align-items-center p-0 text-center">
                                                    <i className={`fa-solid fa-file-pdf text-muted mx-auto fa-2x`} />
                                                </div>
                                                <div className="col-8 align-self-center">
                                                    <p>Download </p>
                                                </div>
                                                <div className="col-2 align-self-center">
                                                    {/* <i className="fa-solid fa-arrow-up-right-from-square" /> */}
                                                    <Icon.Download size={15} />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                        {researches.length > 0 && (
                            <div className="col-12">
                                <hr></hr>
                                <h5 className="py-3">Recent research that are using {Method.ResearchMethodName}</h5>
                                <div className="row" id="researchlist">
                                    <ResearchCard col="4" researches={researches} />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Layout>
    );
};
export default Method;
