import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ResearchCard from "../../organism/Card/ResearchCard";
import { checkPermission } from "../../../utils/permissionHelper";
import * as Icon from "react-feather";
import _ from "lodash";
import nl2br from "react-nl2br";
import Layout from "../../templates/Layout";
import { urlImage } from "../../../utils/imageHelper";
import Skeleton from "react-loading-skeleton";

const Researcher = () => {
    const { user } = useSelector((state) => state.auth);
    const { id: ResearcherID } = useParams();
    const [Researcher, setResearcher] = useState("");
    const [researches, setResearch] = useState([]);
    const [products, setProducts] = useState([]);
    const [methods, setMethods] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getResearcherById();
        getResearches();
    }, []);
    useEffect(() => {
        populateMethods();
    }, [researches]);

    useEffect(() => {
        const tmpProducts = _.groupBy(researches, (research) => research.ProductID);
        setProducts({ ...tmpProducts, length: _.size(tmpProducts) });
    }, [researches]);

    const getResearcherById = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/researchers/${ResearcherID}`);
        setResearcher(response.data);
    };

    const getResearches = async () => {
        setLoading(true);
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/researches`, {
            ResearcherID: [ResearcherID],
        });
        setResearch(response.data);
        setLoading(false);
    };
    const populateMethods = () => {
        var tempMethods = [];
        researches.map((research) => research.researchmethods.map((method) => tempMethods.push(method.ResearchMethodName)));
        var tmpMethods = _.toArray(_.groupBy(tempMethods));
        var newMethods = [];
        tmpMethods.map((method) => newMethods.push([method[0], method?.length]));
        setMethods(newMethods);
    };
    return (
        <Layout>
            {/* <EditBio ResearcherID={Researcher.ResearcherID} ResearcherBio={Researcher.ResearcherBio} /> */}
            <div className="container">
                <div className="row py-3">
                    <div className="col-md-3">
                        <div className="row">
                            {/* <div className="col-12">
                                <div
                                    title={Researcher.ResearcherName}
                                    className="responsive-circle-avatar rounded-circle"
                                    style={{
                                        width: "250px",
                                        height: "250px",
                                        backgroundImage: `url(${
                                            Researcher.ResearcherImage
                                                ? `${urlImage(Researcher.ResearcherImage)}`
                                                : "/assets/images/No_Image_Available_2.jpg"
                                        })`,
                                    }}
                                ></div>
                            </div> */}
                            <div className="col-12 text-center">
                                <h6 className="my-3">{Researcher.ResearcherName}</h6>
                            </div>
                            <div className="col-12 text-justify">
                                <p>{nl2br(Researcher.ResearcherBio)}</p>
                                {/* <hr></hr> */}
                            </div>
                            <div className="col-12">
                                <p className="fw-bold">Statistics</p>
                                <p className="mb-0">Total Research: {researches.length}</p>
                                <p className="mb-0">Total Products: {products.length}</p>
                                <p className="mb-0">Methods Used: {methods.length}</p>
                                <hr></hr>
                                <div>
                                    {methods.map((method, index) => (
                                        <span key={index} className="me-1 badge bg-secondary">
                                            {method[0]} ({method[1]}){" "}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className="col-12">
                                <hr></hr>
                                {Researcher.email === user?.email && (
                                    <>
                                        {/* <Link to="#" data-toggle="modal" data-target="#editBioModal">
                                            <button variant="outline" className="w-100" iconLeft={<Icon.Edit />}>Edit</button>
                                        </Link> */}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        {researches.length > 0 && (
                            <>
                                <h6 className="my-3">Research Conducted by {Researcher.ResearcherName}</h6>
                                <div className="row">
                                    <ResearchCard col="6" researches={researches} />
                                </div>
                            </>
                        )}
                        <div className="row">
                            {loading && (
                                <>
                                    <h6 className="my-3">Research Conducted by {Researcher?.ResearcherName}</h6>
                                    <div className="col-lg-6">
                                        <Skeleton height={140} />
                                    </div>
                                    <div className="col-lg-6">
                                        <Skeleton height={140} />
                                    </div>
                                    <div className="col-lg-6">
                                        <Skeleton height={140} />
                                    </div>
                                    <div className="col-lg-6">
                                        <Skeleton height={140} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default Researcher;
