import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../../templates/Layout";
import ResearchInfo from "./ResearchInfo";
import ResearchTabs from "./ResearchTabs";
import ResearchAskAI from "./ResearchAskAI";
import ResearchSideBar from "./ResearchSideBar";
import ResearchEmbedReport from "./ResearchEmbedReport";
import ResearchAITools from "./ResearchAITools";
import Skeleton from "react-loading-skeleton";
import InsightTracker from "./InsightTracker";
import { STATUS_OPTIONS, TYPE_OPTIONS, SEVERITY_OPTIONS, JOURNEY_OPTIONS } from "../../../constants/trackerOptions.js";

const nl2br = require("react-nl2br");

const Research = () => {
    const { isError, user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const { id: ResearchID } = useParams();
    const [research, setResearch] = useState("");
    const [trackers, setTrackers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [supported, setSupported] = useState();

    const testConnection = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/test-slides-connection/${research.ResearchID}`);
            setSupported(response.data);
        } catch (error) {
            setSupported(error?.response?.data || false);
        }
    };

    useEffect(() => {
        if (research) {
            testConnection();
        }
    }, [research]);

    useEffect(() => {
        getResearchById();
    }, []);

    useEffect(() => {
        if (isError) {
            navigate("/");
        }
    }, [isError, navigate]);

    const getResearchById = async () => {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/researches/${ResearchID}`);
        setResearch(response.data);
        setTrackers(response.data.trackers || []);
        setLoading(false);
    };

    const calculateSummary = () => {
        if (!trackers) return {};

        const summary = STATUS_OPTIONS.reduce((acc, status) => {
            acc[status.value] = 0;
            return acc;
        }, {});

        trackers.forEach((tracker) => {
            if (summary.hasOwnProperty(tracker.status)) {
                summary[tracker.status]++;
            }
        });

        return summary;
    };
    var tags = research?.ResearchTags?.split(",");
    // Utility function to format the text
    const formatText = (text) => {
        if (text) {
            // Split the text by newlines
            return text.split("\n").map((line, index) => {
                // Replace **text** with <strong>text</strong>
                const formattedLine = line.replace(/\*\*(.*?)\*\*/g, (match, p1) => `<strong>${p1}</strong>`);
                // Use dangerouslySetInnerHTML to render the HTML
                return <div key={index} dangerouslySetInnerHTML={{ __html: formattedLine }} />;
            });
        }
    };

    const summary = calculateSummary();

    return (
        <Layout>
            <div className="container">
                <div className="row pb-5 pt-3">
                    {trackers.length > 0 && (
                        <div className="col-12 mb-3">
                            <div className="card card-body">
                                <h6>Insight Tracker Summary</h6>
                                <div className="d-flex justify-content-between">
                                    {STATUS_OPTIONS.map((status) => (
                                        <div key={status.value}>
                                            <i className={`bi ${status.iconClass} ${status.colorClass}`}></i> {status.text}:{" "}
                                            {summary[status.value]}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="col-md-9 pb-3">
                        <div className="card">
                            <div className="card-body">
                                {!loading ? (
                                    <>
                                        <ResearchInfo Research={research}></ResearchInfo>
                                        <ResearchAskAI supported={supported} Research={research}></ResearchAskAI>
                                        <ResearchAITools
                                            supported={supported}
                                            getResearchById={getResearchById}
                                            Research={research}
                                        ></ResearchAITools>
                                        <ResearchEmbedReport Research={research}></ResearchEmbedReport>
                                        <hr></hr>
                                        <ResearchTabs Research={research} formatText={formatText}></ResearchTabs>
                                        {tags && (
                                            <div>
                                                <span className="mb-0 small me-2">Tags:</span>
                                                {tags?.map((tag) => {
                                                    return <span className="badge bg-secondary me-1">{tag}</span>;
                                                })}
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Skeleton className="mb-3" height={35}></Skeleton>
                                        <Skeleton></Skeleton>
                                        <Skeleton></Skeleton>
                                        <hr></hr>
                                        <Skeleton height={350}></Skeleton>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <ResearchSideBar Research={research} formatText={formatText}></ResearchSideBar>
                    {!loading && trackers.length > 0 && <InsightTracker setTrackers={setTrackers} trackers={trackers} />}
                </div>
            </div>
        </Layout>
    );
};

export default Research;
