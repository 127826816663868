// src/components/ResearchInfo.js
import React from "react";
import * as Icon from "react-feather";
import ResearchTabs from "./ResearchTabs";
import { Link } from "react-router-dom";

const ResearchInfo = ({ Research }) => {
    return (
        <>
            <h5>{Research?.ResearchName}</h5>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-end mt-2 small">
                        {Research?.product?.tribe?.TribeName && Research?.product?.tribe?.TribeName !== "Undefined" && (
                            <div className="me-auto">
                                Tribe: {Research?.product?.tribe?.TribeName} ({Research?.product?.tribe?.TribeID}){" "}
                            </div>
                        )}
                        <div className="">Publish Date: {new Date(Research?.createdAt).toLocaleDateString("id-ID")}</div>
                    </div>
                    <div className="d-flex justify-content-end small">
                        {Research?.product?.ProductName && Research?.product?.ProductName !== "Undefined" && (
                            <Link className="text-dark d-block me-auto" to={`/product/${Research?.product?.ProductID}`}>
                                Product: {Research?.product?.ProductName}
                            </Link>
                        )}
                        <div className="">
                            Conduct Date:&nbsp;
                            {Research?.ResearchStartDate && `${new Date(Research?.ResearchStartDate).toLocaleDateString("id-ID")}`}
                            {Research?.ResearchEndDate && ` - ${new Date(Research?.ResearchEndDate).toLocaleDateString("id-ID")}`}
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
        </>
    );
};

export default ResearchInfo;
