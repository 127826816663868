import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setKeywordFilter } from "../../../redux/filterSlice";
import * as Icon from 'react-feather';
const SearchBar = ({ onFilterChange }) => {
    const dispatch = useDispatch();
    const { keyword } = useSelector((state) => state.filter);
    const [searchTerm, setSearchTerm] = useState('')
    useEffect(() => {
        onFilterChange();
    }, [keyword]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            dispatch(setKeywordFilter(searchTerm));
            if (onFilterChange) {
                onFilterChange();
            }
        }, 2000)
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])
    return (
        <div className="input-group ">
            <input onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm} type="text" name="search" placeholder="Type product, researcher, or research project here..." className="form-control" />
            <button type="submit" className="input-group-text">
                <Icon.Search size={18} />
            </button>
        </div>
    );
};

export default SearchBar;
