import React, { useRef, useEffect, useState } from "react";
import DashSidebar from "../../organism/DashSidebar";
import Layout from "../../templates/Layout";
import axios from "axios";
import { FormGroup } from "../../formGroup";
import { showToast } from "../../../utils/toastHelper";
import { BeatLoader } from "react-spinners";

const Tools = () => {
    const [gridData, setGridData] = useState([
        ["tidak repot", null, null],
        ["Lebih mudah harusnya", null, null],
        ["Belum", null, null],
        ["Belum", null, null],
        ["Lebih praktis", null, null],
        ["ekonomis", null, null],
        ["Lebih simple", null, null],
        ["Lebih mudah", null, null],
        ["lebih baik lagi", null, null],
        ["Mempercepat proses", null, null],
        ["Supaya praktis aja", null, null],
        ["Setuju saja selama itu harus ", null, null],
        ["Agar lebih cepat proses pembuatan dokumennya", null, null],
        ["praktis", null, null],
    ]);
    const gridRef = useRef(null);
    const [gridInstance, setGridInstance] = useState(null);
    const [apiKey, setApiKey] = useState(process.env.REACT_APP_OPENAI_API || "");
    const [question, setQuestion] = useState(
        "Mengapa Bapak/Ibu setuju atau tidak setuju dengan penggunaan e-Materai pada dokumen penagihan di PaDi UMKM?"
    );
    const [themes, setThemes] = useState(
        "Kemudahan dan Praktis, Efisiensi, Digitalisasi dan Inovasi, Keamanan dan Validitas Data, Hemat Biaya, Adaptasi dan Kepatuhan, Legalitas dan Kepastian Harga"
    );
    const [instruction, setInstruction] = useState(
        "You are assigned to support a UX Researcher by systematically categorizing texts into predefined topics. Your task involves analyzing each text and assigning it to the most relevant topic listed in the provided data. If a text does not align with any existing topics, you are now required to create a new, appropriately named topic for that text. It is crucial to first attempt to utilize the existing topics for classification; only create a new topic when it is clear that the text does not fit any existing categories."
        // "You are assigned to support a UX Researcher by systematically categorizing texts into predefined topics. Your task involves analyzing each text and assigning it to the most relevant topic listed in the provided data. If a text does not align with any existing topics, please classify it under 'Others'. It is crucial not to create new topics but to utilize the existing ones for classification."
    );
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (gridRef.current && !gridInstance) {
            const options = {
                data: gridData,
                contextMenuItems: [
                    "copy",
                    "cut",
                    "paste",
                    "delete_rows",
                    "insert_rows_before",
                    "insert_rows_after",
                    "delete_cols",
                    "insert_cols_before",
                    "insert_cols_after",
                ],
                rowHeaderLabelAlign: "left",
                topBar: [],
                bottomBar: [
                    // "fullscreenButton",
                    // "credits",
                ],
            };
            const grid = new DataGridXL(gridRef.current, options);
            setGridInstance(grid);
        }
    }, [gridData, gridInstance]);

    const handleUndo = () => {
        if (gridInstance) {
            gridInstance.undo();
        }
    };
    const handleFit = () => {
        if (gridInstance) {
            gridInstance.resizeColsToFit([0, 1]);
        }
    };

    const handleRedo = () => {
        if (gridInstance) {
            gridInstance.redo();
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!apiKey || !apiKey.length) {
            return showToast("Please fill your API Key", "error");
        }
        try {
            setLoading(true);
            if (gridInstance) {
                // Assuming getData() is the method to retrieve grid data. Replace with actual method.
                const currentGridData = gridInstance.getData();
                console.log("Grid Data on Submit:", currentGridData);
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/ai-classify`, {
                    table: currentGridData,
                    apikey: apiKey,
                    question,
                    themes,
                    instruction,
                });
                console.log(response.data);
                // Assuming response.data is a 2D array of the same size as the grid
                const newData = response.data;

                // Define the range for the entire grid
                const rowCount = newData.length;
                const colCount = (newData[0] || []).length;
                const range = [
                    { x: 0, y: 0 },
                    { x: colCount - 1, y: rowCount - 1 },
                ];

                // Set the new data to the grid
                gridInstance.setCellValues(range, newData);
                // You can now use this data as needed, e.g., send it to an API, process it, etc.
            }
        } catch (error) {
            showToast(error.response?.data?.msg || error.message, "error");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    const handleClear = () => {
        if (gridInstance) {
            // Get the current grid data
            const currentData = gridInstance.getData();

            // Check if there's data in the grid
            if (currentData && currentData.length > 0) {
                // Determine the number of rows and columns
                const rowCount = currentData.length;
                const colCount = currentData[0].length;

                // Define the range from the first cell (0,0) to the last cell
                const range = [
                    { x: 0, y: 0 },
                    { x: colCount - 1, y: rowCount - 1 },
                ];
                gridInstance.clearCellValues(range);
            }
        }
    };

    return (
        <Layout>
            <div className="container">
                <div className="row py-3">
                    <DashSidebar />
                    <div className="col-md-9">
                        <div className="card">
                            <div className="card-body">
                                <h4>Experimental Tools</h4>
                                <div className="row">
                                    <div className="col-md-6">
                                        <img src={"/assets/images/experimental.png"} className="w-100 float-end d-block rounded" />
                                    </div>
                                    <div className="col-md-6">
                                        <p className="small text-muted">Do sentiment or survey classification effortlessly powered by AI.</p>
                                        <div className="my-3">
                                            <FormGroup
                                                value={apiKey}
                                                onChange={(e) => setApiKey(e.target.value)}
                                                label={"API Key"}
                                                id={"API"}
                                                type="text"
                                                required={true}
                                                name={"API"}
                                                autoComplete={"api"}
                                                disabled={loading}
                                            />
                                        </div>
                                        <div className="my-3">
                                            <FormGroup
                                                value={instruction}
                                                onChange={(e) => setInstruction(e.target.value)}
                                                label={"Instruction"}
                                                id={"Instruction"}
                                                type="textarea"
                                                style={{ minHeight: "150px" }}
                                                disabled={loading}
                                            />
                                        </div>
                                        <div className="my-3">
                                            <FormGroup
                                                value={question}
                                                onChange={(e) => setQuestion(e.target.value)}
                                                label={"Question"}
                                                id={"Question"}
                                                type="textarea"
                                                required={true}
                                                style={{ minHeight: "150px" }}
                                                disabled={loading}
                                            />
                                        </div>
                                        <div className="my-3">
                                            <FormGroup
                                                value={themes}
                                                onChange={(e) => setThemes(e.target.value)}
                                                label={"Pre-defined Themes"}
                                                id={"Themes"}
                                                type="textarea"
                                                required={true}
                                                style={{ minHeight: "150px" }}
                                                disabled={loading}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="my-3">
                                    <button className="btn btn-outline-secondary btn-sm" disabled={loading} onClick={handleFit}>
                                        Fit to Size
                                    </button>
                                    <button className="btn btn-outline-secondary btn-sm ms-2" disabled={loading} onClick={handleClear}>
                                        Clear Data
                                    </button>
                                    <button className="btn btn-outline-secondary btn-sm ms-2" disabled={loading} onClick={handleUndo}>
                                        Undo
                                    </button>
                                    <button className="btn btn-outline-secondary btn-sm ms-2" disabled={loading} onClick={handleRedo}>
                                        Redo
                                    </button>

                                    <button
                                        className="btn float-end btn-warning btn-sm ms-2"
                                        disabled={!apiKey.length || loading}
                                        onClick={handleSubmit}
                                    >
                                        Process Data
                                    </button>

                                    {loading && (
                                        <div className="float-end">
                                            <BeatLoader size={8} color="#6c757d"></BeatLoader>
                                        </div>
                                    )}
                                </div>
                                <div id="grid" className="my-3" ref={gridRef} style={{ width: "100%", height: "400px" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Tools;
