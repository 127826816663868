import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Bar, Doughnut } from "react-chartjs-2";
import Layout from "../../templates/Layout";
import Skeleton from "react-loading-skeleton";
import moment from "moment-timezone";
import { FormGroup } from "../../molecules/FormGroup";
import { showToast } from "../../../utils/toastHelper";
import { getBadgeClass } from "../../../utils/trackerHelper";
import { Tooltip as ReactTooltip } from "react-tooltip"; // Import Tooltip component
import ResearchCard from "./ResearchCard";
import InsightTracker from "../research/InsightTracker";
import { Chart } from "react-google-charts"; // Import Google Charts
import { SEVERITY_OPTIONS, STATUS_OPTIONS } from "../../../constants/trackerOptions";
import { AdorableWordCloud, CloudWord, Options, Callbacks } from "adorable-word-cloud"; // Import AdorableWordCloud
import { removeStopwords, ind } from "stopword"; // Import the function and Indonesian stopwords list

const Product = () => {
    const { isError, user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const { id: ProductID } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState();
    const [trackers, setTrackers] = useState([]);

    useEffect(() => {
        if (product) {
            // Map trackers from all researches and include the research object
            const allTrackers = product.researches.flatMap((research) => research.trackers.map((tracker) => ({ ...tracker, research })));
            setTrackers(allTrackers);
        }
    }, [product]);

    useEffect(() => {
        getProductById();
    }, []);

    useEffect(() => {
        if (isError) {
            navigate("/");
        }
    }, [isError, navigate]);

    const getProductById = async () => {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/${ProductID}`);
        setProduct(response.data);
        setLoading(false);
    };

    const calculateJourneyFunnel = (researches) => {
        const funnelData = {
            Explore: { Cosmetic: 0, Minor: 0, Major: 0, Catastrophe: 0 },
            Buy: { Cosmetic: 0, Minor: 0, Major: 0, Catastrophe: 0 },
            Pay: { Cosmetic: 0, Minor: 0, Major: 0, Catastrophe: 0 },
            Activate: { Cosmetic: 0, Minor: 0, Major: 0, Catastrophe: 0 },
            Use: { Cosmetic: 0, Minor: 0, Major: 0, Catastrophe: 0 },
            GetSupport: { Cosmetic: 0, Minor: 0, Major: 0, Catastrophe: 0 },
            Terminate: { Cosmetic: 0, Minor: 0, Major: 0, Catastrophe: 0 },
        };

        researches.forEach((research) => {
            research.trackers.forEach((tracker) => {
                if (tracker.journey in funnelData && tracker.severity) {
                    funnelData[tracker.journey][tracker.severity] += 1;
                }
            });
        });

        return funnelData;
    };
    const renderJourneyFunnel = () => {
        if (loading || !product || !product.researches) {
            return <Skeleton height={400} />;
        }

        const funnelData = calculateJourneyFunnel(product.researches);
        const data = [
            ["Journey Stage", "Cosmetic", "Minor", "Major", "Catastrophe"],
            ...Object.entries(funnelData).map(([stage, severities]) => [
                stage,
                severities.Cosmetic,
                severities.Minor,
                severities.Major,
                severities.Catastrophe,
            ]),
        ];

        const options = {
            title: "Journey Funnel by Insight Severity",
            legend: { position: "top", maxLines: 3 },
            chartArea: { width: "80%", height: "70%" },
            isStacked: true,
            hAxis: { title: "Count", minValue: 0 },
            vAxis: { title: "Journey Stage" },
            colors: SEVERITY_OPTIONS.map((option) => option.backgroundColor), // Use colors from SEVERITY_OPTIONS
            fontName: "Nunito Sans", // Set font to Nunito Sans
        };

        return <Chart chartType="BarChart" width="100%" height="400px" data={data} options={options} />; // Set height to 400px
    };

    const calculateStatusDistribution = (researches) => {
        const statusCounts = {};
        STATUS_OPTIONS.forEach((status) => {
            statusCounts[status.text] = 0; // Use a unique string property
        });

        researches.forEach((research) => {
            research.trackers.forEach((tracker) => {
                if (tracker.status in statusCounts) {
                    statusCounts[tracker.status]++;
                }
            });
        });

        return statusCounts;
    };

    const renderStatusDistribution = () => {
        if (loading || !product || !product.researches) {
            return <Skeleton height={400} />;
        }

        const statusCounts = calculateStatusDistribution(product.researches);
        const data = [
            ["Status", "Count", { role: "style" }],
            ...Object.entries(statusCounts).map(([status, count]) => {
                const statusOption = STATUS_OPTIONS.find((option) => option.text === status);
                return [status, count, statusOption ? statusOption.backgroundClassColor : "#6c757d"];
            }),
        ];

        const options = {
            title: "Insight Status Distribution",
            pieHole: 0.4,
            is3D: false,
            chartArea: { width: "80%", height: "80%" },
            legend: { position: "right" },
            slices: Object.keys(statusCounts).reduce((acc, status, index) => {
                const statusOption = STATUS_OPTIONS.find((option) => option.text === status);
                if (statusOption) {
                    acc[index] = { color: statusOption.backgroundClassColor };
                }
                return acc;
            }, {}),
            fontName: "Nunito Sans", // Set font to Nunito Sans
        };
        return <Chart chartType="PieChart" width="100%" height="400px" data={data} options={options} />;
    };
    const renderWordCloud = () => {
        if (loading || !product || !product.researches) {
            return <Skeleton height={400} />;
        }

        // Create a frequency map of words
        const wordFrequency = {};
        product.researches.forEach((research) => {
            research.trackers.forEach((tracker) => {
                const words = tracker.description.split(" ");
                const filteredWords = removeStopwords(words, ind); // Use Indonesian stopwords
                filteredWords.forEach((word) => {
                    word = word.toLowerCase().replace(/[.,0-9%]/g, ""); // Normalize to lowercase and remove dots, commas, and numbers
                    if (word) {
                        wordFrequency[word] = (wordFrequency[word] || 0) + 1;
                    }
                });
            });
        });

        // Convert the frequency map to an array and sort by frequency
        const sortedWords = Object.entries(wordFrequency)
            .map(([text, value]) => ({ text, value }))
            .sort((a, b) => b.value - a.value);

        // Get the top 5 words
        const topWords = sortedWords.slice(0, 45);

        console.log("Top 5 Word Cloud Data:", topWords); // Debugging: Log the top words array

        const options = {
            colors: ["#B0E650", "#ff7f0e", "#4DD5CB", "#568CEC", "#CE7DFF", "#4FD87D"],
            fontFamily: "JalnanGothic",
            fontSizeRange: [20, 60],
            rotationDivision: 0,
        };

        const callbacks = {
            onWordClick: (word) => {
                console.log(word.text);
            },
        };

        return (
            <div style={{ width: "100%", height: "300px" }}>
                {topWords.length > 0 ? (
                    <>
                        <p
                            className="mb-0"
                            style={{
                                textAnchor: "start",
                                fontFamily: "Nunito Sans",
                                fontSize: "13px",
                                fontWeight: "bold",
                                stroke: "none",
                                strokeWidth: "0",
                                color: "#000000",
                            }}
                        >
                            Most Discussed Topics
                        </p>
                        <AdorableWordCloud words={topWords} options={options} callbacks={callbacks} />
                    </>
                ) : (
                    <p>No words to display</p>
                )}
            </div>
        );
    };

    const renderTopSevereIssues = () => {
        if (loading || !product || !product.researches) {
            return <Skeleton height={200} />;
        }

        // Create a list of all trackers with their severity
        const allTrackers = product.researches.flatMap((research) => research.trackers);

        // Filter out trackers with status "Completed" or "Rejected"
        const filteredTrackers = allTrackers.filter((tracker) => tracker.status !== "Completed" && tracker.status !== "Rejected");

        // Sort trackers by severity
        const severityOrder = ["Catastrophe", "Major", "Minor", "Cosmetic"];
        const sortedTrackers = filteredTrackers.sort((a, b) => severityOrder.indexOf(a.severity) - severityOrder.indexOf(b.severity));

        // Collect top 5 most severe issues
        const topSevereIssues = [];
        for (const severity of severityOrder) {
            const issues = sortedTrackers.filter((tracker) => tracker.severity === severity);
            topSevereIssues.push(...issues);
            if (topSevereIssues.length >= 5) break;
        }

        return (
            <div className="top-severe-issues">
                <p
                    className="mb-0"
                    style={{
                        textAnchor: "start",
                        fontFamily: "Nunito Sans",
                        fontSize: "13px",
                        fontWeight: "bold",
                        stroke: "none",
                        strokeWidth: "0",
                        color: "#000000",
                    }}
                >
                    Top 3 Most Severe Unaddressed Issues
                </p>
                {topSevereIssues.length > 0 ? (
                    <ul>
                        {topSevereIssues.slice(0, 3).map((tracker, index) => {
                            const severityOption = SEVERITY_OPTIONS.find((option) => option.value === tracker.severity);
                            return (
                                <li key={index}>
                                    <span
                                        className="badge"
                                        style={{ backgroundColor: severityOption ? severityOption.backgroundColor : "#6c757d" }}
                                    >
                                        {tracker.severity}
                                    </span>{" "}
                                    {tracker.description}
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <p>No severe issues available</p>
                )}
            </div>
        );
    };
    return (
        <Layout>
            <div className="container">
                {/* Individual research cards */}
                <div className="row">
                    {!loading && product && (
                        <div className="col-12 my-3">
                            <div className="card card-body">
                                <h4>{product.ProductName}</h4>
                                <div className="row">
                                    <div className="col-12 col-lg-6">{renderJourneyFunnel()}</div>{" "}
                                    <div className="col-12 col-lg-6">{renderStatusDistribution()}</div>
                                    <div className="col-12 col-lg-6">{renderWordCloud()}</div>
                                    <div className="col-12 col-lg-6">{renderTopSevereIssues()}</div> {/* Updated line */}
                                </div>
                                {!loading && product && (
                                    <div className="accordion mt-3" id="researchAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingAll">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseAll"
                                                    aria-expanded="false"
                                                    aria-controls="collapseAll"
                                                >
                                                    <i className="bi bi-database me-2"></i> {/* Add this line */}
                                                    Data Sources
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseAll"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingAll"
                                                data-bs-parent="#researchAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <div className="row">
                                                        {product.researches.map((research, index) => (
                                                            <ResearchCard research={research} index={index} key={index} />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* <hr></hr> */}
                                {/* <h6>
                                    <i class="bi bi-magic"></i> Ask AI
                                </h6> */}
                                {/* <div className="row">
                                    <div className="col-lg-11 col-10">
                                        <FormGroup
                                            label={"Ask AI and get answer for your questions about user insight from this product"}
                                            value={prompt}
                                            onChange={(e) => setPrompt(e.target.value)}
                                            id={"prompt"}
                                            type="textarea"
                                            maxLength={500}
                                            style={{ height: "80px" }}
                                            required={true}
                                        ></FormGroup>
                                        <div className="mt-3">
                                            Data sources:{" "}
                                            <button type="button" class="btn btn-light border btn-sm">
                                                Add
                                            </button>
                                        </div>
                                        <p className="small mb-0 my-3 text-muted">Ask AI can make mistakes. Check important information.</p>
                                    </div>
                                    <div className="col-2 col-lg-1">
                                        <button
                                            onClick={() =>
                                                showToast(
                                                    "Ask AI at the product level is coming soon! In the meantime, you can try it out on the research view, which is already available.",
                                                    "info"
                                                )
                                            }
                                            className={`btn btn-outline-primary mb-2`}
                                        >
                                            <i className="bi bi-send"></i>
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    )}
                    <div className="mb-3">
                        {!loading && trackers && (
                            <InsightTracker researches={product?.researches} trackers={trackers} setTrackers={setTrackers} />
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Product;
