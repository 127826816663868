import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import ResearchCard from "../../organism/Card/ResearchCard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Layout from "../../templates/Layout";
import ResearchFilter from "../../organism/Filter/ResearchFilter";
import { Waypoint } from "react-waypoint";
import SearchBar from "../../molecules/ResearchFilter/SearchBar";
// var sort = "";
const ResearchList = () => {
    const [initialized, setInitialized] = useState(false);
    const [researches, setResearch] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [lastPage, setLastPage] = useState(false);
    const [filterChange, setFilterChange] = useState(false);
    const { tribe, product, method, keyword } = useSelector((state) => state.filter);
    useEffect(() => {
        if (!lastPage) {
            getResearches();
        }
    }, [page]);

    // Filter Handling Goes Here,
    // Disclaimer: this code can be optimized by getResearches is currently being called only on pageChange,
    // since the filterChange the page is still = 1 if user not scrolled, then when we setPage(1) getResearches() is not invoked
    // because still at page 1.
    function onFilterChange() {
        if (initialized) {
            setFilterChange(true); // 1. First we setFilterChange to true, indicating we're changing the filter
        }
    }
    useEffect(() => {
        if (filterChange) {
            setResearch([]); // 2. After filterChange state has changed, we then empty the researches state = []
        }
    }, [filterChange]);
    useEffect(() => {
        if (researches?.length === 0 && filterChange) {
            setLastPage(false); // 3. After we empty the researches state, we then set state this is not lastPage so getResearches() can be called at the bottom
        }
    }, [researches, filterChange]);
    useEffect(() => {
        if (!lastPage && filterChange && page === 1) {
            getResearches(); // 4. If page is still 1, then getResearches() at the useEffect[page] will not be invoked, so we call it here.
        }
        if (!lastPage && filterChange && page !== 1) {
            setPage(1); // 4. If page has changed, then getResearches() at the useEffect[page] invoked, so we call setPage(1) here
        }
    }, [page, lastPage, filterChange]);
    //End of filter handling

    const getResearches = async () => {
        setLoading(true);
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/researches`, {
            TribeID: tribe,
            ProductID: product,
            Keyword: keyword,
            MethodID: method,
            ArchivedOnly: true,
            Page: page,
        });
        if (response.data.length === 0) {
            setLastPage(true);
        }
        if (researches.length > 0) {
            setResearch((researches) => researches.concat(response.data));
        } else {
            setResearch(response.data);
        }
        setLoading(false);
        setFilterChange(false);
        setInitialized(true);
    };

    return (
        <Layout>
            <div className="container-fluid" style={{ backgroundColor: "#FEFAF5" }}>
                <div className="container">
                    <div className="row justify-content-center align-items-end pt-5">
                        <div className="col-md-7 ">
                            <div className="pt-5">
                                <h3 style={{ color: "#402884" }}>Research Repository</h3>
                                <div className="mt-1">
                                    <p>Research Repository of Telkom Indonesia’s digital researches.</p>
                                </div>
                                <div className="my-5">
                                    <SearchBar onFilterChange={onFilterChange}></SearchBar>
                                </div>
                                <div className="form-group">
                                    {/* <input disabled={loading} onChange={(e) => setfilterKeywords(e.target.value)} placeholder="Search by keywords..." iconLeft={<Icon.Search />} /> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-md-4 d-none d-md-block float-end align-items-end">
                            <img src={"/assets/images/Research Repo.png"} className="w-100 float-end d-block" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container py-3">
                <div className="row">
                    <div className="col-md-3 ">
                        <ResearchFilter onFilterChange={onFilterChange}></ResearchFilter>
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            {researches.length > 0 && <ResearchCard col="6" researches={researches} />}
                            {loading && (
                                <>
                                    <div className="col-md-6 mb-3">
                                        <Skeleton height={150}></Skeleton>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Skeleton height={150}></Skeleton>
                                    </div>
                                </>
                            )}
                            {lastPage && (
                                <div className="small py-3 text-center text-muted">
                                    That's all, folks! We've reached the end of our research. Stay tuned for more amazing finds soon! 🎉
                                </div>
                            )}
                            {!loading && !lastPage && !filterChange && page % 2 !== 0 && (
                                <Waypoint
                                    onEnter={({ previousPosition, currentPosition, event }) => {
                                        setPage(page + 1);
                                    }}
                                />
                            )}
                            {!loading && !lastPage && !filterChange && page % 2 === 0 && (
                                <div className="my-3 d-flex justify-content-center">
                                    <button className="btn btn-outline-primary" onClick={() => setPage(page + 1)}>
                                        See more
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ResearchList;
