// src/components/ResearchInfo.js
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useSelector } from "react-redux";
import { FormGroup } from "../../molecules/FormGroup";
import { Tooltip } from "react-tooltip";
import { showToast } from "../../../utils/toastHelper";
import { sendPrompt } from "../../../utils/promptHelper";
import axios from "axios";

const ResearchAskAI = ({ Research, supported }) => {
    const { user } = useSelector((state) => state.auth);
    const [prompt, setPrompt] = useState("");
    const [promptResult, setPromptResult] = useState();
    const [loading, setLoading] = useState(false);

    const formatResult = (text) => {
        // Split text into lines
        if (text) {
            return text?.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                    {formatLine(line)}
                    {index !== text.split("\n").length - 1 && <br />}
                </React.Fragment>
            ));
        }
    };
    const formatLine = (line) => {
        let formattedLine = line;

        // Convert Markdown bold **text** to <strong>text</strong>
        formattedLine = formattedLine.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

        // Convert Markdown italic _text_ to <em>text</em>
        formattedLine = formattedLine.replace(/_(.*?)_/g, "<em>$1</em>");

        // Handle combined bold and italic **_text_**
        formattedLine = formattedLine.replace(/\*\*_(.*?)_\*\*/g, "<strong><em>$1</em></strong>");
        formattedLine = formattedLine.replace(/_\*\*(.*?)\*\*_/g, "<em><strong>$1</strong></em>");

        return React.createElement("span", { dangerouslySetInnerHTML: { __html: formattedLine } });
    };

    const handleTestPrompt = async () => {
        if (!prompt || prompt.length === 0) {
            return showToast("Prompt cannot be empty", "error");
        }
        try {
            setLoading(true);
            setPromptResult("Generating...");
            const eventSource = await sendPrompt(prompt, Research.ResearchID);
            let promptRes = "";
            eventSource.onmessage = (event) => {
                if (event.data) {
                    const data = JSON.parse(event.data);
                    if (data.choices[0].finish_reason) {
                        setLoading(false);
                        return eventSource.close();
                    }
                    const newPromptResult = data.choices[0].delta.content;

                    if (newPromptResult) {
                        promptRes = promptRes + newPromptResult;
                        setPromptResult(promptRes); // Update the state
                    }
                }
            };

            // Handle any error
            eventSource.onerror = (error) => {
                showToast("Error receiving data.", "error");
                setPromptResult("Error receiving data.");
                console.error("EventSource failed:", error);
                eventSource.close();
            };
        } catch (error) {
            showToast(`Error ${error.message}`, "error");
            setPromptResult("Error receiving data.");
            setLoading(false);
            console.error(error);
        }
    };
    return (
        <>
            {Research?.researchlinkdetails?.filter(
                (link) =>
                    link.LinkURL != null &&
                    link.LinkID === 1 &&
                    (String(link.LinkURL).includes("presentation") || String(link.LinkURL).includes("document"))
            ).length === 0 || supported?.statusText === "Forbidden" ? (
                <>
                    <div className="text-center">
                        {supported?.statusText === "Forbidden" ? (
                            <p>
                                <span className="text-danger">Ask AI not supported due to the research not being shared</span>.<br></br>
                                <small>
                                    To gain access to Ask AI feature, <b>share view access</b> on this report to email: <br></br>
                                    researcher-hub@telkom.design<br></br>
                                    and then refresh this page.
                                </small>
                            </p>
                        ) : (
                            <p>Ask AI not supported for research that doesn't have Google Slides presentation.</p>
                        )}
                        <hr />
                    </div>
                </>
            ) : (
                <div className="col-md-12">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-lg-11 col-10">
                                <FormGroup
                                    label={"Ask AI and get answer for your questions about this research"}
                                    value={prompt}
                                    onChange={(e) => setPrompt(e.target.value)}
                                    id={"prompt"}
                                    type="textarea"
                                    maxLength={500}
                                    style={{ height: "80px" }}
                                    required={true}
                                ></FormGroup>
                                <p className="small mb-0 my-3 text-muted">Ask AI can make mistakes. Check important information.</p>
                            </div>
                            <div className="col-2 col-lg-1">
                                <Tooltip id="coming-soon-tooltip" place="top" effect="solid">
                                    Coming soon
                                </Tooltip>
                                <button onClick={handleTestPrompt} className={`btn btn-outline-primary mb-2`} data-tooltip-id={undefined}>
                                    <i className="bi bi-send"></i>
                                </button>
                                <Tooltip style={{ zIndex: 99 }} id="data-sources-tooltip" place="top" effect="solid">
                                    Data sources are from Google Slides presentation.
                                </Tooltip>
                                <div>
                                    <i data-tooltip-id="data-sources-tooltip" className="bi bi-info-circle-fill small text-muted"></i>
                                </div>
                            </div>
                            <div className="col-12">
                                {promptResult && promptResult.length > 0 && (
                                    <div className="my-3 p-3 small bg-light rounded">
                                        <label className="text-muted">Answer</label>
                                        <p className="mb-0"> {formatResult(promptResult)}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                </div>
            )}
        </>
    );
};

export default ResearchAskAI;
