import React from "react";
import moment from "moment-timezone";
import { STATUS_OPTIONS, TYPE_OPTIONS, SEVERITY_OPTIONS, JOURNEY_OPTIONS } from "../../../constants/trackerOptions.js";
const TrackerAccordion = ({
    trackers,
    trackerUpdates,
    editingField,
    handleEditClick,
    handleTrackerChange,
    handleSaveChanges,
    handleDeleteTracker,
    statusOptions,
    typeOptions,
    severityOptions,
    journeyOptions,
}) => {
    const renderEditableField = (field, options, trackerData, trackerId) => {
        return editingField.trackerId === trackerId && editingField.field === field ? (
            <select
                value={trackerData[field]}
                onChange={(event) => handleTrackerChange(event, trackerId, field)}
                onBlur={() => handleSaveChanges(trackerId)}
                className="form-select form-select-sm"
                style={{ display: "inline", width: "auto" }}
            >
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        ) : (
            <span
                className={`${
                    field === "status" ? `badge ${STATUS_OPTIONS.find((status) => status.value === trackerData.status).backgroundClass}` : ""
                } ms-2`}
                onClick={() => handleEditClick(trackerId, field)}
                style={{ cursor: "pointer" }}
            >
                {trackerData[field]}
            </span>
        );
    };

    const renderEditableDescription = (trackerData, trackerId) => {
        return editingField.trackerId === trackerId && editingField.field === "description" ? (
            <>
                <input
                    value={trackerData.name}
                    onChange={(event) => handleTrackerChange(event, trackerId, "name")}
                    onBlur={() => handleSaveChanges(trackerId)}
                    className="form-control mb-3"
                />
                <textarea
                    value={trackerData.description}
                    onChange={(event) => handleTrackerChange(event, trackerId, "description")}
                    onBlur={() => handleSaveChanges(trackerId)}
                    className="form-control mb-3"
                    rows="3"
                />
            </>
        ) : (
            <p onClick={() => handleEditClick(trackerId, "description")} style={{ cursor: "pointer" }}>
                {trackerData.description || "No description provided."}
            </p>
        );
    };

    const renderAccordionItem = (tracker, index, accordionId) => {
        const trackerData = trackerUpdates[tracker.TrackerID];

        if (!trackerData) return null;

        const statusOption = STATUS_OPTIONS.find((status) => status.value === trackerData.status);

        return (
            <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={`heading${accordionId}${index}`}>
                    <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${accordionId}${index}`}
                        aria-expanded="false"
                        aria-controls={`collapse${accordionId}${index}`}
                    >
                        {index + 1}. {tracker.name}{" "}
                        <span className={`badge ms-2 ${statusOption.backgroundClass} fw-normal`}>
                            <i className={`bi ${statusOption.iconClass} me-1`}></i>
                            {trackerData.status}
                        </span>
                    </button>
                </h2>
                <div
                    id={`collapse${accordionId}${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${accordionId}${index}`}
                    data-bs-parent={`#accordion${accordionId}`}
                >
                    <div className="accordion-body">
                        <p className="small text-muted mb-3">
                            <i className="bi bi-pencil-square"></i> Click text to edit
                        </p>
                        {renderEditableDescription(trackerData, tracker.TrackerID)}
                        <div className="row">
                            {[
                                { label: "Type", options: typeOptions, field: "type" },
                                { label: "Severity", options: severityOptions, field: "severity" },
                                { label: "Journey", options: journeyOptions, field: "journey" },
                                { label: "Status", options: statusOptions, field: "status" },
                            ].map(({ label, options, field }, i) => (
                                <div className="col-md-6" key={i}>
                                    <p className="mb-0">
                                        <span className="fw-bold">{label}:</span>{" "}
                                        {renderEditableField(field, options, trackerData, tracker.TrackerID)}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <p className="small text-muted my-3">
                            Created by: {tracker.createdBy}
                            {tracker.lastUpdatedBy && (
                                <>
                                    {" "}
                                    | Last updated by: {tracker.lastUpdatedBy} ({moment(tracker.updatedAt).fromNow()})
                                </>
                            )}
                        </p>
                        <p className="mb-0">
                            <a className="small text-danger" role="button" onClick={() => handleDeleteTracker(tracker.TrackerID)}>
                                Delete Insight
                            </a>
                        </p>
                    </div>
                </div>{" "}
            </div>
        );
    };

    const halfLength = Math.ceil(trackers.length / 2);
    const [leftColumn, rightColumn] = [trackers.slice(0, halfLength), trackers.slice(halfLength)];

    return (
        <div className="row">
            {[leftColumn, rightColumn].map((column, i) => (
                <div className="col-md-6" key={i}>
                    <div className={`accordion`} id={`accordion${i === 0 ? "Left" : "Right"}`}>
                        {column.map((tracker, index) =>
                            renderAccordionItem(tracker, index + (i === 1 ? halfLength : 0), i === 0 ? "Left" : "Right")
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TrackerAccordion;
