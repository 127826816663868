import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { showToast } from "../../../utils/toastHelper";
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import { STATUS_OPTIONS, TYPE_OPTIONS, SEVERITY_OPTIONS, JOURNEY_OPTIONS } from "../../../constants/trackerOptions.js";
import TrackerTable from "./TrackerTable";
import TrackerAccordion from "./TrackerAccordion";
import StatusLegend from "./StatusLegend";
import TrackerProgressBar from "./TrackerProgressBar";

const InsightTracker = ({ trackers, setTrackers, researches }) => {
    const { user } = useSelector((state) => state.auth);
    const [editingField, setEditingField] = useState({ trackerId: null, field: null });
    const [trackerUpdates, setTrackerUpdates] = useState({});
    const [viewMode, setViewMode] = useState("table");

    const statusOptions = STATUS_OPTIONS.map((status) => status.value);
    const typeOptions = TYPE_OPTIONS.map((type) => type.value);
    const severityOptions = SEVERITY_OPTIONS.map((severity) => severity.value);
    const journeyOptions = JOURNEY_OPTIONS.map((journey) => journey.value);

    useEffect(() => {
        // Initialize tracker updates state with each tracker's current attributes
        const initialUpdates = trackers.reduce((acc, tracker) => {
            acc[tracker.TrackerID] = {
                name: tracker.name || "",
                description: tracker.description || "",
                status: tracker.status || statusOptions[0],
                type: tracker.type || typeOptions[0],
                severity: tracker.severity || severityOptions[0],
                journey: tracker.journey || journeyOptions[0],
            };
            return acc;
        }, {});
        setTrackerUpdates(initialUpdates);
    }, [trackers]);

    const handleEditClick = (trackerId, field) => {
        setEditingField({ trackerId, field });
    };

    const handleTrackerChange = (event, trackerId, field) => {
        setTrackerUpdates((prevUpdates) => ({
            ...prevUpdates,
            [trackerId]: {
                ...prevUpdates[trackerId],
                [field]: event.target.value,
            },
        }));
    };

    const handleSaveChanges = async (trackerId) => {
        const originalTracker = trackers.find((tracker) => tracker.TrackerID === trackerId);
        const updatedTracker = trackerUpdates[trackerId];

        if (JSON.stringify(originalTracker) !== JSON.stringify(updatedTracker)) {
            try {
                await axios.patch(`${process.env.REACT_APP_API_URL}/trackers/${trackerId}`, updatedTracker);
                showToast("Tracker successfully updated", "success");

                const updatedTrackers = trackers.map((tracker) =>
                    tracker.TrackerID === trackerId ? { ...tracker, ...updatedTracker } : tracker
                );
                setTrackers(updatedTrackers);
            } catch (error) {
                showToast(`Error updating tracker: ${error.response?.data?.msg || error.message}`, "error");
                console.error("Error updating tracker", error);
                setTrackerUpdates((prevUpdates) => ({
                    ...prevUpdates,
                    [trackerId]: { ...originalTracker },
                }));
            } finally {
                setEditingField({ trackerId: null, field: null });
            }
        } else {
            setEditingField({ trackerId: null, field: null });
        }
    };

    const handleDeleteTracker = async (trackerId) => {
        if (window.confirm("Are you sure you want to delete this tracker?")) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/trackers/${trackerId}`);
                showToast("Tracker successfully deleted", "success");
                const updatedTrackers = trackers.filter((tracker) => tracker.TrackerID !== trackerId);
                setTrackers(updatedTrackers);
            } catch (error) {
                showToast(`Error deleting tracker: ${error.response?.data?.msg || error.message}`, "error");
                console.error("Error deleting tracker", error);
            }
        }
    };

    const handleExportToExcel = () => {
        const data = trackers.map((tracker) => ({
            Name: tracker.name,
            Description: tracker.description,
            Status: tracker.status,
            Type: tracker.type,
            Severity: tracker.severity,
            Journey: tracker.journey,
            "Created By": tracker.createdBy,
            "Last Updated By": tracker.lastUpdatedBy,
            "Updated At": moment(tracker.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Trackers");

        XLSX.writeFile(workbook, "Research_Insight_Trackers.xlsx");
    };

    return (
        <div className="col-12">
            <div className="card card-body mb-2">
                <div className="d-flex justify-content-between align-items-center">
                    <h5>Insight Tracker</h5>
                    <div>
                        <button
                            className="btn btn-sm btn-outline-primary me-2"
                            onClick={() => setViewMode(viewMode === "accordion" ? "table" : "accordion")}
                        >
                            <i className={`bi ${viewMode === "accordion" ? "bi-table" : "bi-list-ul"}`}></i>{" "}
                            {viewMode === "accordion" ? "Table View" : "Accordion View"}
                        </button>
                        <button className="btn btn-sm btn-outline-success" onClick={handleExportToExcel}>
                            <i className="bi bi-file-earmark-excel-fill"></i> Export to Excel
                        </button>
                    </div>
                </div>
                <TrackerProgressBar trackers={trackers} />
                <div className="mt-2" style={{ maxHeight: "450px", overflowY: "auto", overflowX: "clip" }}>
                    {viewMode === "accordion" ? (
                        <TrackerAccordion
                            trackers={trackers}
                            trackerUpdates={trackerUpdates}
                            editingField={editingField}
                            handleEditClick={handleEditClick}
                            handleTrackerChange={handleTrackerChange}
                            handleSaveChanges={handleSaveChanges}
                            handleDeleteTracker={handleDeleteTracker}
                            statusOptions={statusOptions}
                            typeOptions={typeOptions}
                            severityOptions={severityOptions}
                            journeyOptions={journeyOptions}
                        />
                    ) : (
                        <TrackerTable
                            trackers={trackers}
                            trackerUpdates={trackerUpdates}
                            editingField={editingField}
                            handleEditClick={handleEditClick}
                            handleTrackerChange={handleTrackerChange}
                            handleSaveChanges={handleSaveChanges}
                            handleDeleteTracker={handleDeleteTracker}
                            statusOptions={statusOptions}
                            typeOptions={typeOptions}
                            severityOptions={severityOptions}
                            journeyOptions={journeyOptions}
                            researches={researches}
                        />
                    )}
                </div>
                <StatusLegend />
            </div>
        </div>
    );
};

export default InsightTracker;
