import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store from "./app/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import axios from "axios";

/* Custom CSS */
import "./assets/css/custom.css";

/* Skeleton */
import "react-loading-skeleton/dist/skeleton.css";

// React Toastify
import "react-toastify/dist/ReactToastify.css";

axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <ToastContainer />
        <App />
    </Provider>
);
