import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
    const { user } = useSelector((state) => state.auth);
    return (
        <>
            <footer className="container-fluid footer-wrapper">
                <div className="container small">
                    <div className="row py-5">
                        <div className="col-md-4 col-12 pb-5">
                            <p className="small" style={{ textAlign: "justify" }}>
                                Maintained by <b>Research Ops</b> from <b>Chapter Design & Experience (DEX)</b> under the{" "}
                                <b>Division of Digital Business and Technology</b> from Telkom Indonesia
                            </p>
                        </div>
                        {/* <div className="col-md-2 pb-5 offset-md-1">
                            <h6 as="h6" className="pb-3">
                                About Us
                            </h6>
                            <Link className="d-block link-dark py-1" to="/">
                                Home
                            </Link>
                        </div> */}
                        <div className="col-md-3 pb-5 offset-md-1">
                            <h6 className="pb-3">Guideline</h6>
                            <Link className="d-block link-dark py-1" to="/methods">
                                <p>Research Methods</p>
                            </Link>
                        </div>
                        {user && (
                            <div className="col-md-3 ">
                                <h6 className="pb-3">Request Research</h6>
                                <Link className="text-decoration-none" to={"https://airtable.com/appHFBB3JcZbM5kbw/shrntA7CNapnPzx35"} target="_blank">
                                    <button className="btn btn-primary btn-sm">Request Research</button>
                                </Link>
                            </div>
                        )}
                        <div className="col-12 text-center pt-5">
                            <hr />
                            <div className="row pt-3">
                                <div className="col-md-2 offset-2 col-8 offset-md-5 px-5">
                                    <svg
                                        preserveAspectRatio="xMidYMid meet"
                                        data-bbox="0 0 311.824 113.386"
                                        viewBox="0 0 311.824 113.386"
                                        xmlns="http://www.w3.org/2000/svg"
                                        data-type="color"
                                        role="presentation"
                                        aria-hidden="true"
                                        aria-labelledby="svgcid-dnvujweukkhm"
                                    >
                                        <title id="svgcid-dnvujweukkhm"></title>
                                        <g>
                                            <g>
                                                <path
                                                    d="M85.043 0h-.002v28.349h.002a28.346 28.346 0 1 1 0 56.692h-.002v28.345h.002A56.693 56.693 0 0 0 85.043 0Z"
                                                    fill="#eb5b2b"
                                                    data-color="1"
                                                ></path>
                                                <path fill="#25378c" d="M28.346 56.692V28.345H0l28.346 28.347z" data-color="2"></path>
                                                <path
                                                    fill="#25378c"
                                                    d="M85.04 85.039h-.004V28.345H56.692v56.696h.004l28.344 28.344V85.039z"
                                                    data-color="2"
                                                ></path>
                                                <path
                                                    d="M178.357 45.553a6.087 6.087 0 0 1-2.29-1.387 7.438 7.438 0 0 1-2.07-5.638v-10.35h-3.92v-4.933h3.92v-6.342h5.77v6.342h5.506v4.934h-5.505v9.292a3.396 3.396 0 0 0 .749 2.468 2.692 2.692 0 0 0 2.113.704 3.763 3.763 0 0 0 1.387-.22 10.64 10.64 0 0 0 1.432-.75v5.638a10.816 10.816 0 0 1-4.007.75 9.083 9.083 0 0 1-3.085-.508Z"
                                                    fill="#1d1d1b"
                                                    data-color="3"
                                                ></path>
                                                <path
                                                    d="M193.775 44.87a11.02 11.02 0 0 1-4.206-4.25 12.822 12.822 0 0 1-.023-12.067 11.545 11.545 0 0 1 4.14-4.383 11.028 11.028 0 0 1 5.903-1.63 11.482 11.482 0 0 1 5.989 1.499 9.79 9.79 0 0 1 3.812 4.095 12.754 12.754 0 0 1 1.297 5.77 13.463 13.463 0 0 1-.132 1.938h-16.87a6.357 6.357 0 0 0 2.137 4.073 6.232 6.232 0 0 0 4.075 1.387 6.498 6.498 0 0 0 3.412-.858 6.322 6.322 0 0 0 2.269-2.312l4.669 2.29a11.259 11.259 0 0 1-10.438 5.99 11.908 11.908 0 0 1-6.034-1.541Zm11.362-13.125a4.46 4.46 0 0 0-.77-2.091 5.373 5.373 0 0 0-1.895-1.717 5.686 5.686 0 0 0-2.84-.683 5.412 5.412 0 0 0-3.545 1.21 6.799 6.799 0 0 0-2.136 3.281Z"
                                                    fill="#1d1d1b"
                                                    data-color="3"
                                                ></path>
                                                <path d="M215.132 14.173h5.77v31.535h-5.77Z" fill="#1d1d1b" data-color="3"></path>
                                                <path
                                                    d="M226.584 14.173h5.77V31.35h.308l8.061-8.104h7.09v.353l-8.498 8.324 8.896 13.432v.353h-6.828l-6.122-9.82-2.907 2.862v6.958h-5.77Z"
                                                    fill="#1d1d1b"
                                                    data-color="3"
                                                ></path>
                                                <path
                                                    d="M255.082 44.85a11.115 11.115 0 0 1-4.25-4.273 12.957 12.957 0 0 1 0-12.178 11.077 11.077 0 0 1 4.25-4.296 13.033 13.033 0 0 1 12.376 0 11.077 11.077 0 0 1 4.251 4.296 12.957 12.957 0 0 1 0 12.178 11.115 11.115 0 0 1-4.25 4.272 13.033 13.033 0 0 1-12.377 0Zm9.294-4.56a5.83 5.83 0 0 0 2.247-2.29 7.13 7.13 0 0 0 .835-3.524 7.036 7.036 0 0 0-.835-3.478 5.83 5.83 0 0 0-2.247-2.29 6.381 6.381 0 0 0-6.165 0 5.93 5.93 0 0 0-2.269 2.29 6.86 6.86 0 0 0-.86 3.478 6.985 6.985 0 0 0 .86 3.502 5.893 5.893 0 0 0 2.27 2.312 6.381 6.381 0 0 0 6.164 0Z"
                                                    fill="#1d1d1b"
                                                    data-color="3"
                                                ></path>
                                                <path
                                                    d="M277.19 23.245h5.42v2.819h.35a7.547 7.547 0 0 1 2.843-2.577 8.369 8.369 0 0 1 3.985-.946 7.537 7.537 0 0 1 4.14 1.123 6.379 6.379 0 0 1 2.51 2.84 8.477 8.477 0 0 1 3.017-2.863 8.913 8.913 0 0 1 4.516-1.1 7.242 7.242 0 0 1 5.836 2.356 9.515 9.515 0 0 1 2.003 6.364v14.447h-5.728v-13.39a4.841 4.841 0 0 0-.989-3.26 3.449 3.449 0 0 0-2.752-1.145 4.27 4.27 0 0 0-3.592 1.785 7.593 7.593 0 0 0-1.34 4.69v11.32h-5.771v-13.39a4.69 4.69 0 0 0-1.036-3.26 3.743 3.743 0 0 0-2.928-1.144 4.027 4.027 0 0 0-3.413 1.785 7.774 7.774 0 0 0-1.3 4.69v11.319h-5.771Z"
                                                    fill="#1d1d1b"
                                                    data-color="3"
                                                ></path>
                                                <path
                                                    d="M177.055 88.178a10.689 10.689 0 0 1-3.92-4.227 14.057 14.057 0 0 1 0-12.355 10.666 10.666 0 0 1 3.92-4.25 10.4 10.4 0 0 1 5.527-1.519 8.114 8.114 0 0 1 4.052.991 7.392 7.392 0 0 1 2.687 2.445h.353l-.353-3.173v-8.63h5.726v31.534h-5.373v-2.686h-.353a6.823 6.823 0 0 1-2.665 2.422 8.4 8.4 0 0 1-4.074.968 10.384 10.384 0 0 1-5.527-1.52Zm9.535-4.645a6.137 6.137 0 0 0 2.247-2.335 7.47 7.47 0 0 0 0-6.87 6 6 0 0 0-5.285-3.128 5.89 5.89 0 0 0-2.995.815 5.96 5.96 0 0 0-2.247 2.312 7.47 7.47 0 0 0 0 6.871 6.124 6.124 0 0 0 2.247 2.335 5.774 5.774 0 0 0 2.995.837 5.85 5.85 0 0 0 3.038-.837Z"
                                                    fill="#1d1d1b"
                                                    data-color="3"
                                                ></path>
                                                <path
                                                    d="M205.002 88.157a11.02 11.02 0 0 1-4.206-4.25 12.822 12.822 0 0 1-.024-12.067 11.545 11.545 0 0 1 4.14-4.384 11.026 11.026 0 0 1 5.902-1.629 11.495 11.495 0 0 1 5.993 1.498 9.789 9.789 0 0 1 3.806 4.095 12.707 12.707 0 0 1 1.298 5.77 14.01 14.01 0 0 1-.13 1.938h-16.869a6.357 6.357 0 0 0 2.137 4.074 6.231 6.231 0 0 0 4.073 1.387 6.503 6.503 0 0 0 3.412-.859 6.36 6.36 0 0 0 2.273-2.311l4.666 2.29a11.257 11.257 0 0 1-10.437 5.99 11.908 11.908 0 0 1-6.034-1.542Zm11.36-13.126a4.473 4.473 0 0 0-.767-2.091 5.445 5.445 0 0 0-1.892-1.717 5.707 5.707 0 0 0-2.846-.683 5.41 5.41 0 0 0-3.543 1.21 6.799 6.799 0 0 0-2.136 3.281Z"
                                                    fill="#1d1d1b"
                                                    data-color="3"
                                                ></path>
                                                <path
                                                    d="M228.499 88.024a8.893 8.893 0 0 1-3.545-4.36l5.15-2.245a5.25 5.25 0 0 0 2.007 2.4 5.535 5.535 0 0 0 3.015.815 6.102 6.102 0 0 0 2.645-.508 1.71 1.71 0 0 0 1.054-1.65 1.786 1.786 0 0 0-.968-1.652 11.093 11.093 0 0 0-2.907-.991l-2.641-.572a9.662 9.662 0 0 1-4.538-2.402 5.775 5.775 0 0 1-1.807-4.337 5.625 5.625 0 0 1 1.169-3.48 7.494 7.494 0 0 1 3.172-2.378 11.373 11.373 0 0 1 4.427-.837q6.957 0 9.29 4.889l-4.932 2.158a5.084 5.084 0 0 0-6.606-1.828 1.612 1.612 0 0 0-.882 1.387q0 1.586 2.997 2.38l3.301.792a10.51 10.51 0 0 1 5.043 2.554 5.782 5.782 0 0 1 1.699 4.23 6.109 6.109 0 0 1-1.258 3.807 8.233 8.233 0 0 1-3.434 2.578 12.237 12.237 0 0 1-4.824.924 11.819 11.819 0 0 1-6.627-1.674Z"
                                                    fill="#1d1d1b"
                                                    data-color="3"
                                                ></path>
                                                <path
                                                    d="M249.22 62.965a3.616 3.616 0 0 1-1.101-2.643 3.541 3.541 0 0 1 1.1-2.62 3.642 3.642 0 0 1 2.645-1.08 3.684 3.684 0 0 1 3.7 3.7 3.638 3.638 0 0 1-1.08 2.643 3.54 3.54 0 0 1-2.62 1.1 3.613 3.613 0 0 1-2.645-1.1Zm-.263 3.566h5.768v22.463h-5.768Z"
                                                    fill="#1d1d1b"
                                                    data-color="3"
                                                ></path>
                                                <path
                                                    d="M263.8 97.297a9.465 9.465 0 0 1-3.789-4.604l5.416-2.2a6.13 6.13 0 0 0 9.8 1.936 6.596 6.596 0 0 0 1.609-4.756v-1.762h-.351a6.855 6.855 0 0 1-2.775 2.29 9.27 9.27 0 0 1-3.92.793 9.92 9.92 0 0 1-5.176-1.432 10.536 10.536 0 0 1-3.854-4.052 13.51 13.51 0 0 1 0-12.199 10.536 10.536 0 0 1 3.854-4.052 9.936 9.936 0 0 1 5.175-1.432 9.27 9.27 0 0 1 3.921.792 6.87 6.87 0 0 1 2.775 2.29h.35v-2.378h5.55v20.922a13.12 13.12 0 0 1-1.41 6.254 9.841 9.841 0 0 1-4.007 4.074 13.864 13.864 0 0 1-13.168-.484Zm11.319-15.24a7.24 7.24 0 0 0 .022-9.337 6.074 6.074 0 0 0-8.348.021 7.208 7.208 0 0 0 0 9.339 5.78 5.78 0 0 0 4.186 1.672 5.683 5.683 0 0 0 4.14-1.695Z"
                                                    fill="#1d1d1b"
                                                    data-color="3"
                                                ></path>
                                                <path
                                                    d="M288.019 66.531h5.42v2.82h.35a7.111 7.111 0 0 1 2.864-2.578 8.674 8.674 0 0 1 4.007-.946 7.671 7.671 0 0 1 6.165 2.466 10.003 10.003 0 0 1 2.115 6.738v13.963h-5.767V75.738a4.74 4.74 0 0 0-1.104-3.348 3.988 3.988 0 0 0-3.082-1.19 4.567 4.567 0 0 0-3.764 1.806 7.063 7.063 0 0 0-1.434 4.536v11.452h-5.77Z"
                                                    fill="#1d1d1b"
                                                    data-color="3"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
