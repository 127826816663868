import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Moment from "react-moment";
import "moment-timezone";
import { urlImage } from "../../../utils/imageHelper";
function ResearchCard(props) {
    const { user } = useSelector((state) => state.auth);
    return (
        <>
            {props.researches
                .filter((research) => research.ResearchName)
                .map((research, index) => (
                    <div key={index} className={`col-md-${props.col} nounderline d-flex align-items-stretch`}>
                        <div variant="bordered" className="mb-3 w-100 card card-hoverable" isHoverable isPressable>
                            <Link
                                to={user ? `/research/${research.ResearchID}` : "#"}
                                data-toggle={user ? `` : "modal"}
                                data-target={user ? `` : "#accessResistrictedModal"}
                                target="_blank"
                            >
                                <div className="card-body">
                                    <div className="text-muted" style={{ fontSize: "11px" }}>
                                        {(() => {
                                            if (research.ResearchEndDate) {
                                                return <Moment format="MMMM YYYY">{research.ResearchEndDate}</Moment>;
                                            } else if (research.ResearchStatus === "Report archived") {
                                                return <Moment format="MMMM YYYY">{research.createdAt}</Moment>;
                                            } else {
                                                return research.ResearchStatus;
                                            }
                                        })()}
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-12 pl-0 d-flex align-self-center ">
                                            <p>
                                                <div
                                                    style={{
                                                        maxHeight: 67,
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: 3,
                                                        WebkitBoxOrient: "vertical",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {research.ResearchName}
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-2"
                                        style={{
                                            maxHeight: 45,
                                            display: "-webkit-box",
                                            WebkitLineClamp: 4,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        <p className="small mb-0">
                                            {research.product.ProductID !== 99999 && (
                                                <>
                                                    {research.product?.ProductName ? `${research.product.ProductName}` : ""}
                                                    {research.product?.TribeID ? ` - ${research.product.TribeID}` : ""}
                                                </>
                                            )}
                                        </p>
                                    </div>
                                    {/* <div className="small text-justify text-muted" style={{ height: 55, display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis" }}>
                                            <p  >{research.ResearchDescription?.replace(/(<([^>]+)>)/ig, '')}</p>
                                        </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-8">
                                            <div
                                                style={{
                                                    height: 20,
                                                    display: "-webkit-box",
                                                    WebkitLineClamp: 2,
                                                    WebkitBoxOrient: "vertical",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {research.researchmethods
                                                    ?.filter((method) => method.ResearchMethodName !== "Unspecified")
                                                    .map((method, index) => (
                                                        <span key={index} className="mr-1 badge rounded-pill bg-light text-dark">
                                                            {method.ResearchMethodName}
                                                        </span>
                                                    ))}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-4">
                                            <div className="float-end">
                                                {research.researchers.map((researcher, index) => (
                                                    <div
                                                        key={index}
                                                        title={researcher.ResearcherName}
                                                        className="ms-1 responsive-circle-avatar rounded-circle"
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundImage: `url(${
                                                                researcher.ResearcherImageSmall
                                                                    ? `${urlImage(researcher.ResearcherImageSmall)}`
                                                                    : "/assets/images/No_Image_Available_2.jpg"
                                                            })`,
                                                        }}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                ))}
        </>
    );
}
export default ResearchCard;
