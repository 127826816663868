import React from "react";
import { STATUS_OPTIONS, TYPE_OPTIONS, SEVERITY_OPTIONS, JOURNEY_OPTIONS } from "../../../constants/trackerOptions.js";

const StatusLegend = () => {
    return (
        <div className="my-3">
            <h6>Status Legend</h6>
            <div className="row">
                {[0, 1].map((columnIndex) => (
                    <div key={columnIndex} className="col-md-6">
                        {STATUS_OPTIONS.slice(
                            columnIndex * Math.ceil(STATUS_OPTIONS.length / 2),
                            (columnIndex + 1) * Math.ceil(STATUS_OPTIONS.length / 2)
                        ).map((status) => (
                            <div key={status.value} className="d-flex align-items-center mb-2">
                                <span className={`badge me-2 ${status.backgroundClass} fw-normal`}>
                                    <i className={`bi ${status.iconClass} me-1`}></i>
                                    {status.text}
                                </span>
                                <small className="text-muted">{status.description}</small>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StatusLegend;
