import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setMethodFilter } from "../../../redux/filterSlice";

const MethodFilter = ({ onFilterChange }) => {
    const dispatch = useDispatch();
    const { method } = useSelector((state) => state.filter);
    const [methods, setMethods] = useState([]);
    const [sortedMethods, setSortedMethods] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const getMethods = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/methods`);
        setMethods(response.data);
    };
    useEffect(() => {
        getMethods()
    }, []);

    const sortMethods = () => {
        const sorted = [...methods].sort((a, b) => {
            const aChecked = method.includes(a.MethodID);
            const bChecked = method.includes(b.MethodID);
            return aChecked === bChecked ? 0 : aChecked ? -1 : 1;
        });
        setSortedMethods(sorted);
    };

    useEffect(() => {
        sortMethods();
    }, [methods, method]);

    useEffect(() => {
        setSearchTerm("")
        onFilterChange();
    }, [method]);

    const handleMethodChange = (event) => {
        const methodId = event.target.getAttribute('methodid');
        if (event.target.checked) {
            dispatch(setMethodFilter([...method, methodId]));
        } else {
            const newMethod = method.filter(id => id !== methodId);
            dispatch(setMethodFilter(newMethod));
        }
        if (onFilterChange) {
            onFilterChange();
        }
    };
    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredMethods = sortedMethods.filter(method => method.ResearchMethodName.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className={`accordion-button ${!method.length > 0 && "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target="#methodPanel-collapse">Method</button>
            </h2>
            <div id="methodPanel-collapse" className={`accordion-collapse collapse ${method.length > 0 && "show"}`}>
                <div className="find-container">
                    <input
                        type="text"
                        className="form-control find-input"
                        placeholder="Search method..."
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                    />
                </div>
                <div className="accordion-body scrollable-list">
                    {filteredMethods.map((met, index) => (
                        <div className="form-check" key={index}>
                            <input checked={method?.includes(met.ResearchMethodName)} className="form-check-input" onChange={handleMethodChange} methodid={met.ResearchMethodName} type="checkbox" id={`methodCheck-${met.ResearchMethodName}`} />
                            <label className="form-check-label" htmlFor={`methodCheck-${met.ResearchMethodName}`}> {met.ResearchMethodName}</label>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MethodFilter;
